import {AfterViewInit, Component, ContentChildren, ElementRef, HostBinding, Input, OnDestroy, OnInit, QueryList} from '@angular/core';
import {TranslationKey} from '../../types/available-translations';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ActionItemComponent} from '../action-item/action-item.component';

@Component({
    selector: 'app-action-item-group',
    templateUrl: './action-item-group.component.html',
    styleUrls: ['./action-item-group.component.scss']
})
export class ActionItemGroupComponent extends BasicSubscribableComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() groupTitle: TranslationKey;
    @Input() hideWhenEmpty: boolean;
    @HostBinding('class.hide-action-item-group') hideClass: boolean;
    @ContentChildren(ActionItemComponent, { descendants: true }) contentActionItems: QueryList<ActionItemComponent> | null;
    private hideClassSubject$: BehaviorSubject<boolean>;
    private observer: IntersectionObserver | undefined;

    constructor(
        private hostElement: ElementRef,
    ) {
        super();
        this.groupTitle = 'ACTIONS.TITLE';
        this.hideWhenEmpty = true;
        this.hideClassSubject$ = new BehaviorSubject<boolean>(false);
        this.hideClass = false;
        this.contentActionItems = null;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.hideClassSubject$.pipe(debounceTime(10), distinctUntilChanged())
            .subscribe(hideClass => this.hideClass = hideClass));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    ngAfterViewInit(): void {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.toggleHideClass();
                }
            });
        });
        this.observer.observe(this.hostElement.nativeElement);
    }

    private toggleHideClass(): void {
        this.hideClassSubject$.next(this.hideWhenEmpty && this.contentActionItems?.toArray().length === 0);
    }
}
