<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="hasMarkedOrDeletedItems$ | extAsync:false"
        [isLoading$]="isLoading$"
        [paginatedList]="list"
        filterKey="caption"
        listId="CLICK_FINDER_LIST"
        noElementsMsg="EMPTY_LIST">
    <ng-template appFilteredListListItem let-item>
        <app-document-list-icon-item
                (singleClick)="$event.preventDefault(); navigateToNode(item)"
                [data]="{iconId: item.nodeId}"
                [line]="item?.caption"
                [qaTag]="'node-' + item.nodeId"
                [svgIcon]="item.icon"
                [useCustomIcon]="true"
                [useSubLine]="false"
                appClick>
        </app-document-list-icon-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    <ng-container *ngIf="(isLoading$ | async) === false">
        <app-beginners-help
                *ngIf="(isTagList$ | async); else showHelpForDocumentTypes"
                [helpDescription]="'BEGINNERS_HELP.TAGS.HELP_DESCRIPTION' | translate"
                [helpLink]="'BEGINNERS_HELP.TAGS.HELP_URL' | translate"
                [helpSvgIcon]="ICONS.PROPGROUP_GREEN"
                [helpTitle]="'BEGINNERS_HELP.TAGS.HELP_TITLE' | translate">
        </app-beginners-help>
        <ng-template #showHelpForDocumentTypes>
            <app-beginners-help
                    (helpButtonClicked)="openAddDocumentTypeCategoryCard()"
                    *ngIf="(parentNodes$ | async)?.length === 0"
                    [helpButtonText]="((hasRightToCreateCategory$ | extAsync: false) === true)?('BEGINNERS_HELP.DOCUMENT_TYPE_CATEGORIES.HELP_BUTTON_TEXT' | translate) : undefined"
                    [helpDescription]="'BEGINNERS_HELP.DOCUMENT_TYPE_CATEGORIES.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.DOCUMENT_TYPE_CATEGORIES.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.DOCTYPE"
                    [helpTitle]="'BEGINNERS_HELP.DOCUMENT_TYPE_CATEGORIES.HELP_TITLE' | translate">
            </app-beginners-help>
            <app-beginners-help
                    (helpButtonClicked)="openAddDocumentTypeCard()"
                    *ngIf="(parentNodes$ | async)?.length === 1"
                    [helpButtonText]="((hasCreatingDocumentTypePermission$ | extAsync: false) === true) ? ('BEGINNERS_HELP.DOCUMENT_TYPES.HELP_BUTTON_TEXT' | translate): undefined"
                    [helpDescription]="'BEGINNERS_HELP.DOCUMENT_TYPES.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.DOCUMENT_TYPES.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.DOCTYPE"
                    [helpTitle]="'BEGINNERS_HELP.DOCUMENT_TYPES.HELP_TITLE' | translate">
            </app-beginners-help>
        </ng-template>
    </ng-container>
</ng-template>

<app-floating-action-button
        [data]="{listId: 'CLICK_FINDER_LIST'}"
        location="sidebar">
    <app-action-item-group groupTitle="ACTIONS.TITLE">
        <app-action-item (clicked)="toggleUsingSmartFilter()"
                         *ngIf="(isUsingSmartFilter$ | async); else isNotUsingSmartFilter"
                         [description]="'ACTIONS.SMART_FILTER.ON' | translate"
                         [smallMenuOrderNumber]="1"
                         [svgIcon]="ICONS.SMARTFILTER_ON"
                         tag="smartfilter-on"></app-action-item>
        <ng-template #isNotUsingSmartFilter>
            <app-action-item (clicked)="toggleUsingSmartFilter()"
                             [description]="'ACTIONS.SMART_FILTER.OFF' | translate"
                             [smallMenuOrderNumber]="1"
                             [svgIcon]="ICONS.SMARTFILTER_OFF"
                             tag="smartfilter-off"></app-action-item>
        </ng-template>
        <app-action-item *ngIf="(hasParentNode$ | async) === false && (isTagList$ | async) === false && (hasRightToCreateCategory$ | extAsync: false) === true"
                         [description]="'ACTIONS.DOCUMENT_TYPE_CATEGORY.ADD' | translate"
                         [smallMenuOrderNumber]="2"
                         [svgIcon]="ICONS.ADD_NEW"
                         action="addDocumentTypeCategory"
                         tag="add-document-type-category"></app-action-item>
        <app-action-item *ngIf="(hasParentNode$ | async) === true && (isTagList$ | async) === false && (hasCreatingDocumentTypePermission$ | extAsync: false) === true"
                         [action]="ACTION_TYPES.ADD_DOCUMENT_TYPE"
                         [description]="'ACTIONS.DOCUMENT_TYPE.ADD' | translate"
                         [smallMenuOrderNumber]="2"
                         [svgIcon]="ICONS.ADD_NEW"
                         tag="add-document-type"></app-action-item>
    </app-action-item-group>
</app-floating-action-button>
