import {ServerTypes} from '../../types/server/server-types';
import {ServerAddress} from '../../models/server-address';
import {ServerConfig} from '../../models/configs/server.config';
import {STATIC_CONFIGS} from '../../../configs/static.config';

export function createServerAddress(serverName: ServerTypes): ServerAddress | undefined {
    let serverAddress: ServerAddress | undefined;
    const server: ServerConfig = STATIC_CONFIGS.servers[serverName];
    if (server) {
        serverAddress = {
            loginCount: 0,
            url: server.url,
            displayUrl: server.displayUrl,
            selected: false,
            name: server.name,
            successfulLoggedIn: true,
            serverInformation: {
                serverVersion: '',
                apiVersion: '',
                serverName: '',
            }
        };
    }
    return serverAddress;
}
