import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaginatedList} from '../../util/paginated-list';
import {DocumentViewEvents} from './document-view-events';
import {BehaviorSubject, combineLatest, firstValueFrom, fromEvent, of, Subscription, throwError} from 'rxjs';
import {Vector2D} from '../../models/vector-2d';
import {catchError, debounceTime, distinctUntilChanged, filter, map, skip, switchMap, take, tap} from 'rxjs/operators';
import {VaultService} from '../../services/vault/vault.service';
import {DocumentQuery} from '../../queries/document.query';
import {Document as DocumentModel} from '../../api/models/document';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {TranslationKey} from '../../types/available-translations';
import {DialogService} from '../../services/dialog/dialog.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DocumentViewDrawableComponent} from './document-view-drawable/document-view-drawable.component';
import {TextItemWithSelection} from '../../models/text-item-with-selection';
import {PageFindSpotItem} from 'src/app/api/models/page-find-spot-item';
import {TextItem} from 'src/app/api/models/text-item';
import {SearchQuery} from '../../queries/search.query';
import {DocumentViewTextItemsComponent} from './document-view-text-items/document-view-text-items.component';
import {AppService} from '../../services/app/app.service';
import {AppQuery} from '../../queries/app.query';
import {DocumentViewMode} from '../../models/document-view-mode';
import {DocumentViewAnnotationsComponent} from './document-view-annotations/document-view-annotations.component';
import {DocumentService} from '../../services/document/document.service';
import {MarkLineAnnotation} from 'src/app/api/models/mark-line-annotation';
import {HighlightAnnotation} from 'src/app/api/models/highlight-annotation';
import {defaultAvailableColor} from '../../types/available-colors';
import {NavigationService} from '../../services/navigation/navigation.service';
import {PageFindSpot} from 'src/app/api/models/page-find-spot';
import {searchFoundColors} from '../../util/search-found-colors';
import {Location} from '@angular/common';
import {HapticsService} from '../../services/haptics/haptics.service';
import {AnnotationType} from '../../types/annotation-type';
import {AnnotationService} from '../../services/annotation/annotation.service';
import {AnnotationQuery} from '../../queries/annotation.query';
import {HistoryService} from '../../services/history/history.service';
import {distinctUntilChangedObjectAttribute} from '../../util/distinct-until-changed-object';
import {HistoryItem} from '../../models/history-item';
import {VaultQuery} from '../../queries/vault.query';
import {ListService} from '../../services/list/list.service';
import {DocumentViewPage} from 'src/app/models/document-view-page';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {DocumentsFindSpotsService} from '../../services/search/documents-find-spots/documents-find-spots.service';
import {DocumentsTextItemsService} from '../../services/search/documents-text-items/documents-text-items.service';
import {ACTION_TYPES} from '../../constants/action-type.constants';
import {PermissionQuery} from '../../queries/permission.query';
import {PermissionService} from '../../services/permission/permission.service';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {SearchDocumentQuery} from '../../queries/search-document.query';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent extends BasicSubscribableComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() editModeAvailable: boolean;
    @Input() documentList: PaginatedList<DocumentModel> | undefined;
    @Input() showCheckedOutToast: boolean | undefined;
    @Input() url: Array<string | undefined> | undefined;
    @Input() listUrl: Array<string | undefined> | undefined;
    @Input() listName: string | undefined;

    @ViewChild(DocumentViewAnnotationsComponent) documentViewAnnotations: DocumentViewAnnotationsComponent | undefined;
    @ViewChild(DocumentViewTextItemsComponent) documentViewTextItems: DocumentViewTextItemsComponent | undefined;
    @ViewChild(DocumentViewDrawableComponent) documentViewDrawable: DocumentViewDrawableComponent | undefined;
    @ViewChild('documentElement', { read: ElementRef, static: false }) documentElement: ElementRef | undefined;
    @ViewChild('selectionTool', { read: ElementRef, static: false }) selectionTool: ElementRef | undefined;
    @ViewChild('drawElement', {
        read: DocumentViewDrawableComponent,
        static: false
    }) drawElement: DocumentViewDrawableComponent | undefined;

    @HostBinding('class.before-back') beforeBack: boolean;
    @HostBinding('class.is-calculating-size') isCalculatingSize: boolean;

    isUsingPen$: BehaviorSubject<boolean>;
    documentPosition$: BehaviorSubject<{ translate: Vector2D; scale: number }>;
    selectedDocument$: Observable<DocumentModel | undefined>;
    pageCount: number;
    foundTexts: Array<Array<PageFindSpotItem>>;
    currentPreviewPageNumber$: Observable<number>;
    selectedTextItems$: Observable<Array<TextItem>>;
    selectedTextItems: Array<TextItem>;
    isLoading$: BehaviorSubject<boolean>;
    documentViewMode: DocumentViewMode;
    document: DocumentModel | undefined;
    foundSpotsMatches$: Observable<Array<PageFindSpot> | undefined>;
    sliderPageNumber: number;
    showPageSelectCard: boolean;
    rawImageSize: Vector2D;
    imageSize: Vector2D;
    page$: Observable<DocumentViewPage | undefined>;
    documentIconUrl?: string;
    private drawnElementIds: Array<string>;
    private pages$: BehaviorSubject<Array<DocumentViewPage>>;
    private toast: { dismiss: () => void } | undefined;
    private list$: BehaviorSubject<PaginatedList<DocumentModel> | undefined>;
    private foundItems: Array<Array<TextItemWithSelection>>;
    private listReloadEvent: Subscription | undefined;

    constructor(
        private hostElement: ElementRef,
        private documentQuery: DocumentQuery,
        private vaultQuery: VaultQuery,
        private vaultService: VaultService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private httpClient: HttpClient,
        private searchQuery: SearchQuery,
        private appService: AppService,
        private appQuery: AppQuery,
        private documentService: DocumentService,
        private router: Router,
        private navigationService: NavigationService,
        private location: Location,
        private hapticsService: HapticsService,
        private ref: ChangeDetectorRef,
        private ngZone: NgZone,
        private annotationService: AnnotationService,
        private historyService: HistoryService,
        private annotationQuery: AnnotationQuery,
        private documentViewEvents: DocumentViewEvents,
        private listService: ListService,
        private searchDocumentFindSpotsService: DocumentsFindSpotsService,
        private documentsTextItemsService: DocumentsTextItemsService,
        private permissionQuery: PermissionQuery,
        private permissionService: PermissionService,
        private searchDocumentQuery: SearchDocumentQuery,
    ) {
        super();
        this.isCalculatingSize = false;
        this.beforeBack = false;
        this.editModeAvailable = true;

        this.isUsingPen$ = new BehaviorSubject<boolean>(false);
        this.documentPosition$ = this.documentViewEvents.documentPosition$;
        this.currentPreviewPageNumber$ = this.documentQuery.currentPreviewPage$;
        this.imageSize = { x: 0, y: 0 };
        this.rawImageSize = { x: 0, y: 0 };

        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.showPageSelectCard = false;
        this.pageCount = 0;
        this.foundTexts = [];
        this.selectedTextItems = [];
        this.selectedTextItems$ = this.searchQuery.listSelectedTextItems$;
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.documentViewMode = 'Viewing';
        this.drawnElementIds = [];
        this.appService.setSelectedAvailableColor(defaultAvailableColor);
        this.list$ = new BehaviorSubject<PaginatedList<DocumentModel> | undefined>(undefined);
        this.foundSpotsMatches$ = this.searchQuery.foundSpotsMatches$;
        this.sliderPageNumber = 1;
        this.foundItems = [];

        this.pages$ = new BehaviorSubject<Array<DocumentViewPage>>([]);
        this.page$ =
            combineLatest([
                this.pages$,
                this.currentPreviewPageNumber$
            ])
                .pipe(map(([pages, currentPreviewPageNumber]) => {
                    currentPreviewPageNumber = Math.min(currentPreviewPageNumber, this.pageCount);
                    if (pages[currentPreviewPageNumber]) {
                        return pages[currentPreviewPageNumber];
                    }
                    return undefined;
                }));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.appQuery.selectedAnnotationType$
            .subscribe((type: AnnotationType | undefined) => {
                if (type) {
                    // immediately reset the annotation type - this is used to set the correct view mode
                    this.appService.setAnnotationType(undefined);
                    if (type !== 'sticky' && type !== 'text') {
                        this.setDrawingMode(true);
                        this.documentService.setDrawAnnotationType(type);
                    }
                }
            }));

        this.subscriptions.add(this.documentQuery.documentViewMode$.pipe(debounceTime(100))
            .subscribe(mode => {
                this.documentViewMode = mode;
                if (this.documentViewEvents) {
                    this.documentViewEvents.documentViewMode = mode;
                }
                if (mode !== 'Annotations') {
                    this.documentViewAnnotations?.resetSelectedItemId();
                }
                switch (mode) {
                    case 'Annotations':
                    case 'Drawing':
                    case 'TextItems':
                        this.appService.setShowingSmallMenuContent(true);
                        break;
                }
            }));

        this.subscriptions.add(this.appQuery.isShowingShortMenuContent$.subscribe(isShowingShortMenuContent => {
            if (!isShowingShortMenuContent) {
                if (this.documentViewMode === 'TextItems') {
                    this.setTextItemMode(false);
                    this.documentsTextItemsService.setSelectedTextItems([]);
                    this.documentViewTextItems?.reset();
                }
                if (this.documentViewMode === 'Drawing') {
                    this.setDrawingMode(false);
                }
            }
        }));

        this.subscriptions.add(this.selectedTextItems$.subscribe(items => {
            this.selectedTextItems = items || [];
        }));

        this.subscriptions.add(this.isLoading$.subscribe(isLoading => {
            if (isLoading) {
                this.appService.showSpinner();
            } else {
                this.appService.hideSpinner();
            }
        }));

        this.subscriptions.add(
            combineLatest([
                this.selectedDocument$.pipe(
                    distinctUntilChanged(),
                    distinctUntilChangedObjectAttribute('id'),
                    tap(document => {
                        this.document = document;
                        this.documentIconUrl = '/' + STATIC_CONFIGS.paths.icons + '/' + this.document?.iconId + '?size=Large';
                    }),
                ),
                this.activatedRoute.paramMap.pipe(map(p => p.get('searchId')), distinctUntilChanged()),
                this.documentService.refreshCurrentViewEvent.pipe(switchMap(async (value: void, index: number) => {
                    if (index === 0) {
                        return;
                    }
                    const currentPage = await this.getCurrentPreviewPage();
                    await this.loadAndSetPageImage(currentPage);
                }))
            ])
                .subscribe(async ([document, paramMap, refreshCurrentViewEvent]: [DocumentModel | undefined, string | null, void]) => {
                    this.reset();
                    this.setViewMode();
                    this.documentViewEvents?.disableEvents();

                    if (document) {

                        await this.vaultService.setActiveVault(document.vaultId);

                        // update app history
                        const url = this.router.url.split('/');
                        // router.url returns string starting with '/'
                        url.shift();
                        if (!this.appQuery.getIsShowingTaskSearch()) {
                            const historyItem: HistoryItem = {
                                title: document.name,
                                subTitle: this.vaultQuery.getVaultNameById(document.vaultId),
                                icon: '/' + STATIC_CONFIGS.paths.icons + '/' + document.iconId + '?size=Medium'
                            };
                            this.historyService.addNavigationHistory(historyItem,
                                url);
                        }

                        let list: PaginatedList<DocumentModel> | undefined;
                        if (this.documentList) {
                            list = this.documentList;
                        }
                        if (!list && this.listName) {
                            list = this.listService.getList(this.listName);
                        }
                        if (!list) {
                            if (!this.listName) {
                                console.warn('Add the listName attribute to document-view html element!');
                            }
                            const latestList = await firstValueFrom(this.listService.latestList$.pipe(take(1)));
                            if (latestList) {
                                list = latestList;
                            }
                        }
                        if (list) {
                            const isInitialized = list.isInitialized$.getValue();
                            if (!isInitialized) {
                                await list.startList();
                            }
                            this.list$.next(list);
                        }
                        if (this.listReloadEvent) {
                            this.listReloadEvent.unsubscribe();
                        }
                        this.listReloadEvent =
                            this.list$.getValue()
                                ?.listReloadEvent
                                .subscribe(() => {
                                    if (this.list$.getValue()
                                        ?.hasLoadedData()) {
                                        this.documentService.fetchDocument(document.id);
                                    }
                                });

                        this.pageCount = document?.pageCount as number;
                        this.documentViewEvents?.setHasMultiplePages(this.pageCount > 1);
                        const hasFoundSpotOnPage = await this.showFoundSpots(true);

                        if (!hasFoundSpotOnPage) {
                            await this.setCurrentPage(1);
                            if (await this.canShowStampsDialog()) {
                                this.appService.setCurrentActionMenuContent(ACTION_TYPES.STAMPS);
                            }
                        }

                        this.showToast(document);

                        this.documentViewEvents?.enableEvents();
                    }
                })
        );

        this.subscriptions.add(this.documentQuery.currentPreviewPage$.pipe(skip(1))
            .subscribe(async () => {
                await this.pageChanged();
            }));

        this.subscriptions.add(this.list$.pipe(
                filter(l => l !== undefined),
                switchMap((l) => l ? l.numberData$ : of(0))
            )
            .subscribe((numData: number) => {
                this.documentViewEvents?.setHasListSiblings((numData !== undefined && numData > 0));
            }));

        this.subscriptions.add(fromEvent(window, 'resize')
            .pipe(
                tap(() => {
                    this.isCalculatingSize = true;
                    this.ref.detectChanges();
                }),
                debounceTime(100)
            )
            .subscribe(() => {
                this.calcImageSize();
            }));

        this.subscriptions.add(this.annotationService.actionMenuOnNext.subscribe(() => {
            const currentStampId = this.annotationQuery.getCurrentStampId();
            if (!(this.documentViewAnnotations && this.documentViewAnnotations.selectedAnnotation) || !!currentStampId) {
                this.navigateToNextDocument()
                    .then();
            }
        }));

        this.subscriptions.add(this.annotationService.actionMenuOnNextStampDocument.subscribe(() => {
            const currentStampId = this.annotationQuery.getCurrentStampId();
            if (!(this.documentViewAnnotations && this.documentViewAnnotations.selectedAnnotation) || !!currentStampId) {
                this.navigateToNextDocument(true)
                    .then(() => {
                        this.appService.setShowingSmallMenuContent(false);
                        this.appService.setShowingSmallMenuSidebar(false);
                        this.appService.setCurrentActionMenuContent(ACTION_TYPES.STAMPS);
                    });
            }
        }));

        this.documentService.refreshCurrentViewEvent.emit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.listReloadEvent) {
            this.listReloadEvent.unsubscribe();
        }
        this.documentsTextItemsService.setSelectedTextItems(undefined);
        this.appService.setShowingSmallMenuContent(false);
        this.appService.removeCurrentActionMenuContent();
        this.documentService.unsetSelectedDocuments();
        if (this.toast) {
            this.toast.dismiss();
        }
        this.documentService.resetDocumentAssignments();
        this.documentService.setDocumentViewMode('Viewing');
        this.searchDocumentFindSpotsService.unsetFoundSpots();
        this.documentViewAnnotations?.resetSelectedItemId();
        window.document.head.querySelectorAll('style[custom="true"]')
            .forEach(s => s.parentElement?.removeChild(s));
    }

    ngAfterViewInit(): void {
        // at-root and host-context is buggy -> this is needed to hide search/task overlay on portrait mode
        const style = window.document.createElement('style');
        style.innerHTML = '@media (min-width: 600px) and (max-width: 1025px) and (orientation: portrait) {app-history-overlay,app-search-overview{display: none !important;}}';
        style.setAttribute('custom', 'true');
        window.document.head.appendChild(style);

        let locationBackTimeOut: number | undefined;
        let zoomBackTimeOut: number | undefined;
        this.documentViewEvents?.init(this.hostElement.nativeElement, this.documentElement?.nativeElement);
        this.documentViewEvents?.setOnZoom(async (zoomStep: number, minZoom: number, maxZoom: number): Promise<number> => {
            return new Promise<number>((resolve) => {
                if (locationBackTimeOut !== undefined) {
                    this.appService.hideSpinner();
                    window.clearTimeout(locationBackTimeOut);
                    locationBackTimeOut = undefined;
                }
                if (zoomBackTimeOut !== undefined) {
                    window.clearTimeout(zoomBackTimeOut);
                    zoomBackTimeOut = undefined;
                }
                if (zoomStep === minZoom) {
                    this.beforeBack = true;
                    this.appService.showSpinner();
                    locationBackTimeOut = window.setTimeout(() => {
                        locationBackTimeOut = undefined;
                        this.reset();
                        this.appService.hideSpinner();
                        if (this.listUrl) {
                            this.navigationService.navigate(this.listUrl)
                                .then();
                        } else {
                            this.location.back();
                        }
                    }, 1000);
                    resolve(zoomStep);
                } else {
                    this.beforeBack = false;
                    this.appService.hideSpinner();
                    if (zoomStep < 1.3 && zoomStep > 0.7) {
                        zoomBackTimeOut = window.setTimeout(() => {
                            zoomBackTimeOut = undefined;
                            resolve(1.0);
                        }, 1000);
                    } else {
                        resolve(zoomStep);
                    }
                }
            });
        });
        this.subscriptions.add(this.documentViewEvents?.swipeLeftEvent.subscribe(() => {
            this.navigateToNextDocument()
                .then();
        }));
        this.subscriptions.add(this.documentViewEvents?.swipeRightEvent.subscribe(() => {
            this.navigateToPreviousDocument()
                .then();
        }));
        this.subscriptions.add(this.documentViewEvents?.swipeUpEvent.subscribe(() => {
            this.toNextPage()
                .then();
        }));
        this.subscriptions.add(this.documentViewEvents?.swipeDownEvent.subscribe(() => {
            this.toPrevPage()
                .then();
        }));
        this.documentViewEvents?.setOnClicked(async (pos: Vector2D) => {
            if (this.documentViewMode !== 'Annotations') {
                return;
            }

            const itemId = await this.documentViewAnnotations?.getItemIdOnPosition(pos);
            if (itemId) {
                await this.documentViewAnnotations?.setSelectedItemId(itemId);
                this.setAnnotationMode(true);
                this.appService.removeCurrentActionMenuContent();
                this.appService.setShowingSmallMenuContent(true);
            } else {
                this.documentViewAnnotations?.resetSelectedItemId();
            }
        });
        this.documentViewEvents?.setOnPressing(async (pos: Vector2D, endPos?: Vector2D) => {
            if (this.documentViewMode !== 'TextItems' || !endPos) {
                return;
            }
            this.documentViewTextItems?.setSelectionToolPositionByTextItem(pos, endPos);
        });
        this.documentViewEvents?.setOnPressStart(async (pos: Vector2D, endPos?: Vector2D) => {
            if (this.documentViewMode === 'Drawing') {
                return;
            }

            const itemId = await this.documentViewAnnotations?.getItemIdOnPosition(pos);
            if (itemId) {
                await this.documentViewAnnotations?.setSelectedItemId(itemId);
                this.setAnnotationMode(true);
                this.appService.removeCurrentActionMenuContent();
                this.appService.setShowingSmallMenuContent(true);
                this.documentViewTextItems?.reset();
                this.hapticsService.hapticsMedium()
                    .then();
            } else {
                this.documentViewAnnotations?.resetSelectedItemId();
                if (this.documentViewMode === 'Annotations') {
                    return;
                }
                this.appService.removeCurrentActionMenuContent();
                this.appService.setShowingSmallMenuContent(false);
                const textItems: Array<TextItem> | undefined = await this.documentViewTextItems?.setSelectionToolPositionByTextItem(pos, endPos);
                if (textItems && textItems.length > 0) {
                    this.setTextItemMode(true);
                    this.hapticsService.hapticsMedium()
                        .then();
                }
            }
        });
        this.documentViewEvents?.setOnPressStartEnd(async (pos: Vector2D, endPos?: Vector2D) => {
            if (this.documentViewMode !== 'TextItems') {
                return;
            }

            await this.documentViewTextItems?.setSelectionToolPositionByTextItem(pos, endPos);
        });
        this.documentViewEvents?.setOnRevert(() => {
            if (this.drawElement) {
                this.drawElement.revertLayer();
            }
        });

        if (this.hostElement.nativeElement && this.documentElement) {
            const onPointerDown = (e: PointerEvent): void => {
                if (this.documentViewMode !== 'Viewing' || !this.editModeAvailable) {
                    return;
                }
                if (e.pointerType === 'pen') {
                    this.isUsingPen$.next(true);
                    this.setDrawingMode(true);
                    this.documentService.setDrawAnnotationType('ink');
                } else {
                    this.isUsingPen$.next(false);
                }
            };

            this.hostElement.nativeElement.removeEventListener('pointerdown', onPointerDown);
            this.hostElement.nativeElement.addEventListener('pointerdown', onPointerDown);
        }
    }

    async layerDrawingDone(): Promise<void> {
        const type = await firstValueFrom(this.documentQuery.drawAnnotationType$.pipe(take(1)));
        if (type === 'ink') {
            const eInkData = this.drawElement?.getImage();
            if (eInkData) {
                this.documentViewAnnotations?.addTemporaryAnnotationToPage('inks', eInkData)
                    .then(element => {
                        if (element) {
                            this.drawnElementIds.push(element.id);
                            this.drawElement?.clearDataAndRedraw();
                        }
                    });
            }
        }
        if (type === 'line') {
            const lineData: MarkLineAnnotation | undefined = this.drawElement?.getLineData();
            if (lineData) {
                this.documentViewAnnotations?.addTemporaryAnnotationToPage('markLines', lineData)
                    .then(element => {
                        if (element) {
                            this.drawnElementIds.push(element.id);
                            this.drawElement?.clearDataAndRedraw();
                        }
                    });
            }
        }
        if (type === 'highlight') {
            const highlightData: HighlightAnnotation | undefined = this.drawElement?.getHighlightData();
            if (highlightData) {
                this.documentViewAnnotations?.addTemporaryAnnotationToPage('highlights', highlightData)
                    .then(element => {
                        if (element) {
                            this.drawnElementIds.push(element.id);
                            this.drawElement?.clearDataAndRedraw();
                        }
                    });
            }
        }
    }

    async drawingDone(): Promise<void> {
        const type = await firstValueFrom(this.documentQuery.drawAnnotationType$.pipe(take(1)));
        if (type === 'signature') {
            const signatureData = this.drawElement?.getImage();
            if (signatureData) {
                const element = await this.documentViewAnnotations?.addTemporaryAnnotationToPage('signatures', signatureData);
                if (element) {
                    this.drawnElementIds.push(element.id);
                    this.documentViewAnnotations?.setSelectedItemId(element.id)
                        .then();
                }
            }
        }
        this.setAnnotationMode(true);
        this.documentViewDrawable?.clearDataAndRedraw();
    }

    cancelDrawing(): void {
        this.setAnnotationMode(true);
        this.documentViewAnnotations?.removeElements(this.drawnElementIds)
            .then();
        this.documentViewDrawable?.clearDataAndRedraw();
        this.appService.removeAllCurrentActionMenus();
        this.appService.setShowingSmallMenuContent(false);
    }

    togglePageSelectCard(show: boolean): void {
        let currentPage = this.documentQuery.getCurrentPreviewPage();
        currentPage = Math.min(currentPage, this.pageCount);
        if (this.pageCount > 1) {
            this.appService.removeAllCurrentActionMenus();
            this.sliderPageNumber = currentPage;
            this.showPageSelectCard = show;
        }
    }

    async setCurrentPage(page: number): Promise<void> {
        page = Math.min(page, this.pageCount);
        this.checkPageRange();
        if (await this.getCurrentPreviewPage() === page) {
            await this.pageChanged();
            return;
        }
        this.documentService.setCurrentPreviewPage(page);
    }

    checkPageRange(): void {
        if (this.sliderPageNumber < 1) {
            this.sliderPageNumber = 1;
            return;
        }
        if (this.sliderPageNumber > this.pageCount) {
            this.sliderPageNumber = this.pageCount;
        }
    }

    private async pageChanged(): Promise<void> {
        const currentPage = await this.getCurrentPreviewPage();
        if (currentPage > 0) {
            await this.getTextItemsForPage(currentPage);
            await this.loadAndSetPageImage(currentPage);
            this.preLoadPages(currentPage)
                .then();
        }
    }

    private async loadAndSetPageImage(page: number): Promise<void> {
        this.isLoading$.next(true);
        try {
            const documentViewPage = await this.loadPageImageAndTextItems(page);
            if (documentViewPage?.imageSize) {
                this.rawImageSize.x = documentViewPage.imageSize.x;
                this.rawImageSize.y = documentViewPage.imageSize.y;
                this.calcImageSize();
                this.ref.detectChanges();
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading$.next(false);
        }
    }

    private getNextPageNumber(page: number): number {
        const pageCount = this.pageCount;
        let nextPage = page + 1;
        if (nextPage > pageCount) {
            nextPage = 1;
        }
        return nextPage;
    }

    private getPreviousPageNumber(page: number): number {
        const pageCount = this.pageCount;
        let previousPage = page - 1;
        if (previousPage < 1) {
            previousPage = pageCount;
        }
        return previousPage;
    }

    private async preLoadPages(page: number): Promise<void> {
        const pageCount = this.pageCount;
        if (pageCount > 1) {
            const nextPage = this.getNextPageNumber(page);
            await this.loadPageImageAndTextItems(nextPage);
            if (pageCount > 2) {
                const prevPage = this.getPreviousPageNumber(page);
                await this.loadPageImageAndTextItems(prevPage);
            }
        }
    }

    private reset(): void {
        this.beforeBack = false;
        this.documentsTextItemsService.setSelectedTextItems([]);
        this.pages$.next([]);
        this.foundItems = [];
        this.documentViewEvents?.reset();
    }

    private async navigateToNextDocument(excludeCheckedOut: boolean = false): Promise<void> {
        const list = this.list$.getValue();
        if (list) {
            if (!list.hasLoadedData()) {
                await list.fetchPage(1);
            }
            const nextDocumentId = await list.getNextId(this.document?.id as string, excludeCheckedOut);
            if (nextDocumentId) {
                this.navigateToDocument(nextDocumentId)
                    .then();
            }
        }
    }

    private async navigateToPreviousDocument(excludeCheckedOut: boolean = false): Promise<void> {
        const list = this.list$.getValue();
        if (list) {
            if (!list.hasLoadedData()) {
                await list.fetchPage(1);
            }
            const prevDocumentId = await list.getPreviousId(this.document?.id as string, excludeCheckedOut);
            if (prevDocumentId) {
                this.navigateToDocument(prevDocumentId)
                    .then();
            }
        }
    }

    private async navigateToDocument(documentId: string): Promise<void> {
        if (this.url) {
            this.navigationService.navigate([...this.url, 'document', documentId], {
                    replaceUrl: true
                }, true)
                .then();
        } else {
            const url = this.router.url;
            const urlParts = url.split('/');
            urlParts[urlParts.length - 1] = documentId;
            this.navigationService.navigate(urlParts.join('/'), {
                    replaceUrl: true
                }, true)
                .then();
        }
    }

    private calcImageSize(): void {
        const documentElement = this.documentElement?.nativeElement;
        if (documentElement) {
            this.appService.showSpinner();
            const parent = documentElement.parentElement as HTMLElement;
            const ratio = this.rawImageSize.x / this.rawImageSize.y;
            const paddingBottom = parseInt(window.getComputedStyle(parent, null)
                .getPropertyValue('padding-bottom'), 10);
            const parentWidth = parent.offsetWidth - 32;
            const parentHeight = parent.offsetHeight - 32 - paddingBottom;
            let imageSizeW = parentHeight * ratio;
            let imageSizeH = parentHeight;
            if (imageSizeW > parentWidth) {
                imageSizeW = parentWidth;
                imageSizeH = parentWidth / ratio;
            }
            imageSizeW = Math.ceil(imageSizeW);
            imageSizeH = Math.ceil(imageSizeH);
            this.imageSize.x = imageSizeW;
            this.imageSize.y = imageSizeH;
            this.documentService.setImageSize({
                x: imageSizeW,
                y: imageSizeH
            });
            this.ref.detectChanges();
        }
        window.setTimeout(() => {
            this.ngZone.run(() => {
                this.documentViewAnnotations?.onResize(this.imageSize.x, this.imageSize.y);
                this.documentViewAnnotations?.setImageSize(this.rawImageSize);
                this.documentViewDrawable?.onResize(this.imageSize.x, this.imageSize.y);
                this.documentViewTextItems?.onResize(this.imageSize.x, this.imageSize.y);
                this.isCalculatingSize = false;
                this.appService.hideSpinner();
                this.ref.detectChanges();
            });
        }, 200);
    }

    private async loadPageImageAndTextItems(page: number): Promise<DocumentViewPage | undefined> {
        const documentPage = this.getOrCreateDocumentPage(page);

        if (!documentPage.image) {
            if (this.document && this.document.version > 0) {
                const srcStr = this.appQuery.getInternalApiUrl() + '/documents/' + this.document.id + '/preview-image?page=' + page + '&size=ForAnnotations&fileVersion=' + this.document.fileVersion;
                try {
                    await firstValueFrom(this.httpClient.get(srcStr as string, { responseType: 'blob' })
                        .pipe(catchError((error: HttpErrorResponse) => {
                            return throwError(() => error);
                        }), switchMap(async imageBlob => {
                            return new Promise<void>((resolve, reject) => {
                                const reader = new FileReader();
                                reader.addEventListener('error', (e) => {
                                    reject(e);
                                });
                                reader.addEventListener('load', () => {
                                    if (!reader.result) {
                                        reject(undefined);
                                        return;
                                    }
                                    const imageSrc = reader.result as string;
                                    const image = new Image();
                                    image.addEventListener('load', () => {
                                        documentPage.image = imageSrc;
                                        documentPage.imageSize = {
                                            x: image.width,
                                            y: image.height
                                        };
                                        resolve();
                                    });
                                    image.addEventListener('error', (e) => {
                                        reject(e);
                                    });
                                    image.src = imageSrc;
                                });
                                reader.readAsDataURL(imageBlob);
                            });

                        })));
                } catch (e) {
                    console.error(e);
                    this.dialogService.showError('ERROR_PREVIEW_FILE');
                }
            }
        }
        return documentPage;
    }

    private setDrawingMode(isInDrawingMode: boolean): void {
        if (isInDrawingMode && this.editModeAvailable) {
            this.drawnElementIds = [];
            this.documentService.setDocumentViewMode('Drawing');
        } else {
            this.documentService.setDrawAnnotationType(undefined);
            this.documentService.setDocumentViewMode('Viewing');
        }
    }

    private setTextItemMode(isTextItemMode: boolean): void {
        if (isTextItemMode) {
            this.documentService.setDocumentViewMode('TextItems');
            this.documentService.setDrawAnnotationType(undefined);
        } else {
            this.documentService.setDocumentViewMode('Viewing');
        }
    }

    private setAnnotationMode(isAnnotationMode: boolean): void {
        if (isAnnotationMode) {
            this.documentService.setDocumentViewMode('Annotations');
            this.documentService.setDrawAnnotationType(undefined);
        } else {
            this.documentService.setDocumentViewMode('Viewing');
        }
    }

    private setViewMode(): void {
        this.documentService.setDocumentViewMode('Viewing');
        this.documentService.setDrawAnnotationType(undefined);
    }

    private getOrCreateDocumentPage(pageNumber: number): DocumentViewPage {
        const pages = this.pages$.getValue();
        if (pages[pageNumber]) {
            return pages[pageNumber];
        }
        const page: DocumentViewPage = {};
        pages[pageNumber] = page;
        this.pages$.next(pages);
        return page;
    }

    private async getTextItemsForPage(page: number, showLoading = true): Promise<void> {
        if (showLoading) {
            this.isLoading$.next(true);
        }
        if (this.document) {
            let textItems;
            let foundItems: Array<TextItemWithSelection> = [];
            const documentPage = this.getOrCreateDocumentPage(page);

            if (!documentPage.textItems) {
                textItems = await this.documentsTextItemsService.fetch(this.document as DocumentModel, page) as Array<TextItem>;
                documentPage.textItems = textItems;
                const foundSpotsPage = this.foundTexts[page] || [];
                const foundSpotsPageFlat: Array<string> = foundSpotsPage.map(item => item.text);
                let foundIndex = -1;
                for (const item of textItems) {
                    foundIndex = foundSpotsPageFlat.indexOf(item.text);
                    const copyItem: TextItemWithSelection = { ...item } as TextItemWithSelection;
                    if (foundIndex > -1) {
                        copyItem.selected = searchFoundColors(foundSpotsPage[foundIndex].matchNumber - 1);
                    }
                    foundItems.push(copyItem);
                }
                this.foundItems[page] = foundItems;
            } else {
                foundItems = this.foundItems[page];
            }
            const currentPage = await this.getCurrentPreviewPage();
            if (page === currentPage) {
                documentPage.foundItems = foundItems;
            }
        }
        if (showLoading) {
            this.isLoading$.next(false);
        }
    }

    private showToast(doc: DocumentModel): void {
        if (this.toast) {
            this.toast.dismiss();
        }

        if (doc.visibilityScope !== 'Trashed') {
            if (doc.state !== 'Ready' && doc.state !== 'WaitForClassification') {
                this.toast = this.dialogService.showTopToast(('DOC_STATES.' + doc.state.toUpperCase()) as TranslationKey);
            } else {
                if (doc.checkedOut && this.showCheckedOutToast === true) {
                    if (doc.version === 0) {
                        this.dialogService.showInfo('PREVIEW_AFTER_RELEASE');
                    } else {
                        this.dialogService.showInfo('DOC_IS_PREVIEW');
                    }
                }
            }
        } else {
            this.toast = this.dialogService.showTopToast('DOC_STATES.IS_DELETED' as TranslationKey, undefined, undefined, 'red');
        }
    }

    private async showFoundSpots(isInit: boolean = false): Promise<boolean> {
        const document = this.document;
        if (document) {
            const params = this.activatedRoute.snapshot.paramMap;
            let foundSpotsMatches: Array<PageFindSpot> | undefined;

            let searchId: string | null | undefined = this.searchQuery.getSearchQueryId();

            if (!searchId) {
                if (params && params.get('searchId')) {
                    searchId = params.get('searchId');
                }
            }
            if (params && params.get('storedQueryId')) {
                const searchInfo = this.searchDocumentQuery.getSearchInformation();
                if (searchInfo) {
                    searchId = searchInfo.searchId;
                }
            }

            if (searchId) {
                await this.searchDocumentFindSpotsService.fetch(searchId, document.id);
                foundSpotsMatches = await firstValueFrom(this.foundSpotsMatches$.pipe(take(1)));
            }

            this.foundTexts = [];
            const hasAnyFindings = await firstValueFrom(this.searchQuery.hasAnyFindings$.pipe(take(1)));

            if (hasAnyFindings) {
                const currentPage = await this.getCurrentPreviewPage();
                this.appService.setCurrentActionMenuContent(ACTION_TYPES.FINDINGS);

                if (foundSpotsMatches && foundSpotsMatches[0]) {
                    for (const foundSpotsPage of foundSpotsMatches) {
                        this.foundTexts[foundSpotsPage.pageNo] = foundSpotsPage.findSpotItems;
                    }

                    if (isInit) {
                        if (currentPage !== foundSpotsMatches[0].pageNo) {
                            await this.setCurrentPage(foundSpotsMatches[0].pageNo);
                        } else {
                            await this.pageChanged();
                        }
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private async toNextPage(): Promise<void> {
        const page = await this.getCurrentPreviewPage();
        const nextPage = this.getNextPageNumber(page);
        await this.setCurrentPage(nextPage);
    }

    private async toPrevPage(): Promise<void> {
        const page = await this.getCurrentPreviewPage();
        const nextPage = this.getPreviousPageNumber(page);
        await this.setCurrentPage(nextPage);
    }

    private async getCurrentPreviewPage(): Promise<number> {
        return await firstValueFrom(this.currentPreviewPageNumber$);
    }

    private async canShowStampsDialog(): Promise<boolean> {
        if (this.document) {
            const isTaskPage = await firstValueFrom(this.activatedRoute.url.pipe(map(urlParts => {
                return urlParts.map(parts => parts.path)
                    .filter(urlPart => urlPart.includes('task')).length > 0;
            })));
            if (!isTaskPage) {
                return false;
            }
            await this.permissionService.fetchDocumentPermission(this.document.id, true);
            const showStamps = await firstValueFrom(this.annotationQuery.showStamps$);
            if (showStamps && this.document.state === 'Ready' && this.permissionQuery.hasDocumentPermission(this.document.id, 'DocumentsGetAccessibleStamps')) {
                return true;
            }
        }
        return false;
    }
}
