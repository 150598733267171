import {EventEmitter, Injectable} from '@angular/core';
import {SelectedItem} from '../../models/selected-item';
import {DocumentsService as ApiDocumentsService} from '../../api/services/documents.service';
import {StampsService as ApiStampsService} from '../../api/services/stamps.service';
import {AuthQuery} from '../../queries/auth.query';
import {AnnotationStore} from '../../stores/annotation.store';
import {Stamp} from 'src/app/api/models/stamp';
import {StampingDataInternal} from 'src/app/api/models/stamping-data-internal';
import {StampImagePreview} from 'src/app/api/models/stamp-image-preview';
import {DialogService} from '../dialog/dialog.service';
import {DocumentQuery} from '../../queries/document.query';
import {StampUserInput} from '../../api/models/stamp-user-input';
import {PermissionQuery} from '../../queries/permission.query';
import {permissionsCheckSwitch} from '../../util/permissions-check-switch';
import {errorToMessage} from '../../util/error-to-message';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnnotationService {
    actionMenuOnSave: EventEmitter<void>;
    actionMenuOnSaveDone: EventEmitter<void>;
    actionMenuOnDelete: (() => void) | undefined;
    actionMenuOnCopy: (() => void) | undefined;
    actionMenuOnNext: EventEmitter<void>;
    actionMenuOnNextStampDocument: EventEmitter<void>;
    actionMenuOnClose: EventEmitter<void>;
    actionMenuOnShowAnnotationMenu: (() => void) | undefined;
    actionMenuOnAddElement: EventEmitter<SelectedItem | undefined>;

    constructor(
        private apiDocumentsService: ApiDocumentsService,
        private apiStampsService: ApiStampsService,
        private authQuery: AuthQuery,
        private annotationStore: AnnotationStore,
        private dialogService: DialogService,
        private documentQuery: DocumentQuery,
        private permissionQuery: PermissionQuery,
    ) {
        this.actionMenuOnNextStampDocument = new EventEmitter<void>();
        this.actionMenuOnNext = new EventEmitter<void>();
        this.actionMenuOnClose = new EventEmitter<void>();
        this.actionMenuOnSave = new EventEmitter<void>();
        this.actionMenuOnSaveDone = new EventEmitter<void>();
        this.actionMenuOnAddElement = new EventEmitter<SelectedItem | undefined>();
    }

    setStamps(stamps: Array<Stamp>): void {
        this.annotationStore.update({
            stamps
        });
    }

    setCurrentStampId(currentStampId: string | undefined): void {
        this.annotationStore.update({currentStampId});
    }

    async fetchStampsForDocument(documentId: string): Promise<void> {
        const hasPermission = permissionsCheckSwitch({type: 'Document', documentId, documentPermission: 'DocumentsGetAccessibleStamps'}, this.permissionQuery);
        if (!hasPermission) {
            return;
        }
        this.setLoading(true);

        const document = this.documentQuery.getDocumentById(documentId);
        if (document && !document?.checkedOut) {
            try {
                const stamps: Array<Stamp> = await firstValueFrom(this.apiDocumentsService.DocumentsGetAccessibleStamps({
                    documentId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer()
                }));

                const stampsWithoutUserInput: Array<Stamp> = (await Promise.all(stamps.map(async stamp => {
                    return await this.stampHasUserInput(stamp) ? undefined : stamp;
                }))).filter(Boolean) as Array<Stamp>;

                this.annotationStore.update({
                    stamps: stampsWithoutUserInput
                });
            } catch (e) {
                this.dialogService.showError('STAMP.ERROR_LOADING_STAMPS');
                console.error(e);
            }
        }
        this.setLoading(false);
    }

    async stampHasUserInput(stamp: Stamp): Promise<boolean> {
        const userInput: StampUserInput = await firstValueFrom(this.apiStampsService.StampsStampHasUserInput({
            stampId: stamp.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: this.authQuery.getBearer()
        }));
        return userInput.hasFields;
    }

    async getStampImagePreviewFromDocumentAndStamp(documentId: string, stampId: string, pageNo: number): Promise<StampImagePreview | undefined> {
        let stampImagePreview: StampImagePreview | undefined;
        this.setLoading(true);
        try {
            stampImagePreview = await firstValueFrom(this.apiDocumentsService.DocumentsGetStampPreview({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                documentId,
                stampPreviewData: {
                    stampId,
                    pageNo
                }
            }));
        } catch (e) {
            this.dialogService.showError('STAMP.ERROR_LOADING_STAMP');
            console.error(e);
        }
        this.setLoading(false);
        return stampImagePreview;
    }

    async addStampToDocumentSilent(documentId: string, stampingData: StampingDataInternal): Promise<string | undefined> {
        this.setLoading(true);
        let errorMessage;
        try {
            await firstValueFrom(this.apiDocumentsService.DocumentsStampDocument({
                documentId,
                stampingData,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            console.error(error);
            errorMessage = errorToMessage(error);
        }
        this.setLoading(false);
        return errorMessage;
    }

    async addStampToDocument(documentId: string, stampingData: StampingDataInternal): Promise<void> {
        const errorMessage = await this.addStampToDocumentSilent(documentId, stampingData);
        if (errorMessage) {
            this.dialogService.showError('STAMP.ERROR_ADDING_STAMP');
            console.error(errorMessage);
        }
    }

    setStampMenuToSimple(isSimpleStampMenu: boolean): void {
        this.annotationStore.update({isSimpleStampMenu});
    }

    setLoading(loading: boolean): void {
        this.annotationStore.setLoading(loading);
    }
}
