import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {hasPermissionRouteGuard} from './util/has-permission-route-guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'prefix',
        loadChildren: () => import('./pages/layout-tablet/layout-tablet.module').then(m => m.LayoutTabletModule),
        canActivate: [hasPermissionRouteGuard]
    },
    {
        path: 's',
        loadChildren: () => import('./pages/layout-smartphone/layout-smartphone.module').then(m => m.LayoutSmartphoneModule),
        canActivate: [hasPermissionRouteGuard]
    },
    // needed for existing users which could currently be on a url with the /t
    {
        path: 't',
        redirectTo: '',
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'missing-permission',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'auth/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
