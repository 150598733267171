<app-filtered-list-pagination
        [isLoading$]="isLoading$"
        [paginatedList]="list"
        listId="CLICK_FINDER_DOCUMENTS"
        noElementsMsg="DOCUMENTS_EMPTY_LIST">
    <ng-template appFilteredListListItem let-isDeleted="isDeleted" let-isLargePreview="isLargePreview" let-isList="isList" let-isMarked="isMarked" let-isSmallPreview="isSmallPreview"
                 let-item let-listDisplayType="listDisplayType">
        <app-document-list-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="$event.preventDefault(); openDocument(item)"
                [annotationImageVersion]="item.annotationsVersion"
                [appDragExportDocumentId]="item.id"
                [appDragExportDocumentName]="item.name"
                [data]="item"
                [deleted]="isDeleted"
                [listDisplayType]="listDisplayType"
                [marked]="isMarked"
                [previewImageVersion]="item.fileVersion"
                [qaTag]="'document-' + item.name"
                [selected]="(selectedDocument$ | async)?.id === item.id"
                appClick
                appDragExport></app-document-list-item>
    </ng-template>
</app-filtered-list-pagination>

<app-floating-action-button
        [data]="{listId: 'CLICK_FINDER_DOCUMENTS', 'importTarget' : 'Documents'}"
        [location]="location">
    <app-action-item-group groupTitle="VIEWS">
        <app-action-item [description]="'LIST_VIEW' | translate" [smallMenuOrderNumber]="1" [svgIcon]="ICONS.VIEW_LISTVIEW" action="listView"
                         tag="list-view"></app-action-item>
    </app-action-item-group>
</app-floating-action-button>
