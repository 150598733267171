import {Component, OnDestroy, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {SearchQuery} from '../../../queries/search.query';
import {DocumentService} from '../../../services/document/document.service';
import {searchFoundColors} from '../../../util/search-found-colors';
import {FlatTagDefinition} from '../../../models/flat-tag-definitions';
import {SelectionTagFindSpotItem} from 'src/app/api/models/selection-tag-find-spot-item';
import {SingleLineTagFindSpotItem} from 'src/app/api/models/single-line-tag-find-spot-item';
import {PageFindSpotItem} from 'src/app/api/models/page-find-spot-item';
import {FoundSpot} from '../../../models/found-spot';
import {TagService} from '../../../services/tag/tag.service';
import {Subscription} from 'rxjs';
import {isEmpty, pickBy} from 'lodash';
import {ACTION_TYPES} from '../../../constants/action-type.constants';
import {User} from 'src/app/api/models/user';
import {UserQuery} from '../../../queries/user.query';

interface TagSpots {
    tagDefinition: FlatTagDefinition;
    caption: string;
    data: Array<FoundSpot>;
}

@Component({
    selector: 'app-findings-card',
    templateUrl: './findings-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './findings-card.component.scss']
})
export class FindingsCardComponent extends CardComponent implements OnInit, OnDestroy {
    pageSpots$: Observable<Array<Array<FoundSpot>>>;
    tagSpots$: Observable<Array<TagSpots>> | undefined;

    private subscription: Subscription;

    constructor(
        appService: AppService,
        private searchQuery: SearchQuery,
        private documentService: DocumentService,
        private tagService: TagService,
        private userQuery: UserQuery,
    ) {
        super(appService);
        this.subscription = new Subscription();

        this.pageSpots$ = this.searchQuery.foundSpots$.pipe(
            map(foundSpots => foundSpots?.pageSpots || []),
            map(pageSpots => {
                const pages: Array<any> = [];
                pageSpots.forEach(spot => {
                    pages[spot.pageNo] = this.spotItemsFlattener(spot.findSpotItems);
                });
                return pages;
            })
        );
    }

    ngOnInit(): void {
        this.tagService.fetchAndGetDocumentTagDefinitions(true)
            .then(tagDefinitions => {
                this.tagSpots$ = this.searchQuery.foundSpots$.pipe(
                    map(foundSpots => foundSpots?.tagSpotCollections || []),
                    map(tagSpotCollections => {
                        if (!tagDefinitions) {
                            return [];
                        }
                        const users: Array<User> = this.userQuery.getActiveUsers();
                        const spotMap: { [key: string]: TagSpots } = {};
                        for (const key in tagSpotCollections) {
                            if (tagSpotCollections.hasOwnProperty(key)) {
                                const definitions = tagSpotCollections as { [key: string]: any };
                                const findSpots = definitions[key];
                                for (const spotTag in findSpots) {
                                    if (findSpots.hasOwnProperty(spotTag)) {
                                        const spot = findSpots[spotTag];
                                        const tagDefinitionId = spot.tagDefinitionId;
                                        if (tagDefinitionId in tagDefinitions) {
                                            if (!(tagDefinitionId in spotMap)) {
                                                const tagDefinition = tagDefinitions[tagDefinitionId];
                                                spotMap[tagDefinitionId] = {
                                                    tagDefinition,
                                                    caption: tagDefinition.caption,
                                                    data: [],
                                                };
                                            }
                                            let item = spot;
                                            let value = spot.value || null;
                                            // Searching for a Text like a Username will always return a text match. even if the searched word is not in the document text
                                            if ('findSpotItems' in spot) {
                                                const findSpotItems = spot.findSpotItems;
                                                if (findSpotItems.length > 0) {
                                                    const findSpotItem = findSpotItems[0];
                                                    // Check if Match is a User and prepare value and item to get the correct user instead of a tagId - otherwise just use the searched word as value
                                                    if ('selectionValueId' in findSpotItem) {
                                                        value = tagDefinitionId;
                                                        item = {
                                                            selectedUserIds: [findSpotItem.selectionValueId]
                                                        };
                                                    } else {
                                                        value = findSpotItem.text;
                                                    }
                                                } else {
                                                    continue;
                                                }
                                            }
                                            value = this.tagService.convertValue(value, item, spotMap[tagDefinitionId].tagDefinition, users);
                                            spotMap[tagDefinitionId].data = [
                                                ...spotMap[tagDefinitionId].data, {
                                                    matchColor: searchFoundColors(spot.matchNumber - 1),
                                                    match: value,
                                                    prefix: '',
                                                    suffix: ''
                                                }
                                            ];
                                        }
                                    }
                                }
                            }
                        }
                        return Object.values(spotMap);
                    })
                );
            });

        this.subscription.add(this.searchQuery.foundSpots$.subscribe(foundSpots => {
            if (foundSpots) {
                const isPageSpotsEmpty = foundSpots?.pageSpots.length === 0;
                const isTagSpotCollectionsEmpty = isEmpty(
                    pickBy(foundSpots.tagSpotCollections, value => value.length > 0)
                );
                if (isPageSpotsEmpty && isTagSpotCollectionsEmpty) {
                    this.appService.removeCurrentActionMenuContent();
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    spotItemsFlattener(findSpotItems: Array<SelectionTagFindSpotItem | SingleLineTagFindSpotItem | PageFindSpotItem>): Array<FoundSpot> {
        const list = [];

        function setNewItem() {
            isPrefix = true;
            newItem.prefix = prefix.join(' ');
            newItem.suffix = suffix.join(' ');
            prefix = [];
            suffix = [];
            list.push(newItem);
            newItem = {
                match: '',
                matchColor: '',
                prefix: '',
                suffix: ''
            };
        }

        const length = findSpotItems.length;
        let item;
        let prefix: Array<string> = [];
        let suffix: Array<string> = [];
        let newItem: FoundSpot = {
            match: '',
            matchColor: '',
            prefix: '',
            suffix: ''
        };
        let isPrefix = true;
        for (let i = 0; i < length; ++i) {
            item = findSpotItems[i];
            if (item.type === 'Placeholder') {
                if (i !== 0) {
                    setNewItem();
                    prefix.push('...');
                    if (i + 1 < findSpotItems.length && findSpotItems[i + 1].type === 'Placeholder') {
                        i += 1;
                    }
                }
            }
            if (item.type === 'Match') {
                if (item.matchNumber !== 0) {
                    if (newItem.match !== '') {
                        setNewItem();
                    }
                    isPrefix = false;
                    newItem.match = item.text;
                    newItem.matchColor = searchFoundColors(item.matchNumber - 1);
                }
            } else {
                if (isPrefix) {
                    prefix.push(item.text);
                } else {
                    suffix.push(item.text);
                }
            }
        }
        if (newItem.match !== '') {
            list.push(newItem);
        }
        return list;
    }

    showDocumentPage(page: number): void {
        this.documentService.setCurrentPreviewPage(page);
    }

    showTagCard(): void {
        this.closeActionCard();
        this.appService.setCurrentActionMenuContent(ACTION_TYPES.DOCUMENT_TAGS);
    }
}
