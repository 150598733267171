/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { documentGetPreviewImage } from '../fn/document/document-get-preview-image';
import { DocumentGetPreviewImage$Params } from '../fn/document/document-get-preview-image';
import { documentGetTagList } from '../fn/document/document-get-tag-list';
import { DocumentGetTagList$Params } from '../fn/document/document-get-tag-list';
import { TagSection } from '../models/tag-section';

@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentGetPreviewImage()` */
  static readonly DocumentGetPreviewImagePath = '/document/{documentId}/preview-image';

  /**
   * Returns a preview image of the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentGetPreviewImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentGetPreviewImage$Response(params: DocumentGetPreviewImage$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return documentGetPreviewImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a preview image of the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentGetPreviewImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentGetPreviewImage(params: DocumentGetPreviewImage$Params, context?: HttpContext): Observable<Blob> {
    return this.documentGetPreviewImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `documentGetTagList()` */
  static readonly DocumentGetTagListPath = '/document/{documentId}/tag/list';

  /**
   * Get all tags of the document.
   *
   * Get all tags of the document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentGetTagList()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentGetTagList$Response(params: DocumentGetTagList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagSection>>> {
    return documentGetTagList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tags of the document.
   *
   * Get all tags of the document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentGetTagList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentGetTagList(params: DocumentGetTagList$Params, context?: HttpContext): Observable<Array<TagSection>> {
    return this.documentGetTagList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagSection>>): Array<TagSection> => r.body)
    );
  }

}
