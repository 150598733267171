<h1>{{ 'SERVER_SELECTION.CONNECTIONS' | translate }}</h1>
<div class="scrollable">
    <form (ngSubmit)="addServer()" [formGroup]="form">
        <h2>{{ 'SERVER_SELECTION.ADD' | translate }}</h2>
        <mat-form-field class="full-width-input no-top-padding login-form-field white-form-field server-input-field">
            <input #serverAddressInput [placeholder]="'SERVER_SELECTION.PLACEHOLDER' | translate" data-qa="server-selection-server-input"
                   formControlName="serverAddress" matInput name="host" type="text"/>
            <button [disabled]="!form.valid || form.controls.serverAddress.value === '' || form.controls.serverAddress.value === null" color="accent" mat-icon-button matSuffix type="submit">
                <mat-icon [svgIcon]="ICONS.ADD_NEW"></mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="server-list">
        <h2>{{ 'SERVER_SELECTION.SELECT' | translate }}</h2>
        <app-static-list
                #appStaticList
                [allowedListDisplayTypes]="['list']"
                [listId]="'SERVER_SELECTION.LIST'"
                [noElementsMsg]="'SERVER_SELECTION.NO_SERVER'"
                filterKey="hostName"
                testTag="server-select-list-scroll-viewport">
            <div *ngFor="let item of (serverAddresses$ | async)" [ngStyle]="{width: appStaticList.itemWidth, height: appStaticList.itemHeight}" class="item-wrapper">
                <app-document-list-icon-item
                        (doubleClick)="selectServer(item.displayUrl, false); saveServerSelection()"
                        (singleClick)="selectServer(item.displayUrl)"
                        [data]="item"
                        [fetchAssignments]="false"
                        [light]="true"
                        [line]="item.name || item.displayUrl"
                        [plainName]="true"
                        [qaTag]="'select-server-' + item.displayUrl"
                        [selected]="item.selected"
                        [subLine]="item.displayUrl"
                        [svgIcon]="ICONS.SERVER"
                        [useSubLine]="true"
                        appClick>
                </app-document-list-icon-item>
            </div>
        </app-static-list>
    </div>
</div>
<div class="actions">
    <button (click)="removeSelectedServer()" *ngIf="(selectedServer$ | async)" class="delete-btn bg-white no-box-shadow"
            data-qa="delete-server-selection-button"
            mat-icon-button
            type="button">
        <mat-icon [svgIcon]="ICONS.TRASH"></mat-icon>
    </button>
    <span class="right-buttons">
        <button (click)="saveServerSelection()" *ngIf="(selectedServer$ | async)" class="save-btn bg-white no-box-shadow"
                data-qa="save-server-selection-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
        </button>
        <button (click)="close()" *ngIf="hasServerAddresses$ | async" class="close-btn bg-white no-box-shadow" data-qa="close-server-selection-button"
                mat-mini-fab
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
        </button>
    </span>
</div>
