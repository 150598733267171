import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AppService} from '../../services/app/app.service';
import {ServerAddress} from 'src/app/models/server-address';
import {AppQuery} from '../../queries/app.query';
import {ServerSelectionService} from '../../services/server-selection/server-selection.service';
import {map, take} from 'rxjs/operators';
import {secureProtocol} from '../../constants/server/secure-protocol.constant';
import {IconsComponent} from '../dummy-components/icons.component';

@Component({
    selector: 'app-server-selection',
    templateUrl: './server-selection.component.html',
    styleUrls: ['./server-selection.component.scss']
})
export class ServerSelectionComponent extends IconsComponent implements OnInit, AfterViewInit {
    @ViewChild('serverAddressInput') serverAddressInput: ElementRef | undefined;
    @Output() done: EventEmitter<boolean>;
    public isLoading$: BehaviorSubject<boolean>;
    public form: FormGroup;
    public selectedServer$: BehaviorSubject<ServerAddress | undefined>;
    public hasServerAddresses$: Observable<boolean>;
    public serverAddresses$: Observable<Array<ServerAddress>>;
    private previousSelectedServerHost: string | undefined;

    constructor(
        private formBuilder: FormBuilder,
        private appService: AppService,
        private appQuery: AppQuery,
        private serverSelectionService: ServerSelectionService,
    ) {
        super();
        this.done = new EventEmitter<boolean>();
        this.isLoading$ = new BehaviorSubject<boolean>(true);
        this.selectedServer$ = this.serverSelectionService.selectedServer$;
        this.hasServerAddresses$ = this.serverSelectionService.hasServerAddresses$;
        this.serverAddresses$ = this.serverSelectionService.serverAddresses$.pipe(map(servers => {
            servers.sort((firstServerAddress: ServerAddress, secondServerAddress: ServerAddress) => {
                return (firstServerAddress.name || secureProtocol + firstServerAddress.url).localeCompare((secondServerAddress.name || secureProtocol +
                    secondServerAddress.url));
            });
            return servers;
        }));
        this.form = this.formBuilder.group({
            serverAddress: new FormControl<string | null>(''),
        });
    }

    async ngOnInit(): Promise<void> {
        this.previousSelectedServerHost = this.appQuery.getSelectedServer()?.url;
        this.serverSelectionService.loadServers();
        const hasServerAddresses = await firstValueFrom(this.hasServerAddresses$.pipe(take(1)));
        if (!hasServerAddresses) {
            this.serverSelectionService.addDefaultServerAddress();
            this.serverSelectionService.loadServers();
        }
        this.isLoading$.next(false);
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            this.focusInput();
        }, 500);
    }

    focusInput(): void {
        if (this.serverAddressInput instanceof ElementRef) {
            this.serverAddressInput.nativeElement.focus();
        }
    }

    blurInput(): void {
        if (this.serverAddressInput instanceof ElementRef) {
            this.serverAddressInput.nativeElement.blur();
        }
    }

    close(): void {
        this.appService.selectServer(this.previousSelectedServerHost);
        this.done.emit(false);
    }

    selectServer(hostName: string, deselect: boolean = true): void {
        this.serverSelectionService.selectServer(hostName, deselect);
    }

    addServer(): void {
        if (this.form.valid) {
            const formData = this.form.getRawValue();
            if (formData.serverAddress && formData.serverAddress.length > 4) {
                this.appService.showSpinner();
                const serverUrl = this.serverSelectionService.cleanUrl(formData.serverAddress, false);
                const hasServerHost = this.serverSelectionService.hasServerHost(serverUrl);

                if (!hasServerHost) {
                    const serverInformation = {
                        serverName: '',
                        apiVersion: '',
                        serverVersion: ''
                    };
                    const newServerAddress = {
                        loginCount: 0,
                        displayUrl: serverUrl,
                        url: serverUrl,
                        name: serverInformation.serverName,
                        selected: false,
                        remember: true,
                        successfulLoggedIn: false,
                        serverInformation
                    };
                    this.serverSelectionService.addServer(newServerAddress)
                        .then(success => {
                            if (success) {
                                this.form.reset();
                                this.blurInput();
                            }
                            this.appService.hideSpinner();
                        });
                } else {
                    this.serverSelectionService.selectServer(serverUrl, false);
                    this.form.reset();
                    this.blurInput();
                    this.appService.hideSpinner();
                }
            }
        }
    }

    reloadList(): void {
        this.isLoading$.next(false);
    }

    removeSelectedServer(): void {
        const selectedServer = this.serverSelectionService.selectedServer$.getValue();
        if (selectedServer) {
            this.serverSelectionService.removeServer(selectedServer.name, selectedServer.displayUrl);
        }
    }

    saveServerSelection(): void {
        const hasChanges = this.serverSelectionService.hasChanges();
        if (hasChanges) {
            this.serverSelectionService.saveServers();
        }
        this.done.emit(hasChanges);
    }
}
