import {ComponentRef, Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {VaultSelectorDialogComponent} from '../../components/dialogs/vault-selector-dialog/vault-selector-dialog.component';
import {Vault} from '../../api/models/vault';
import {TranslateService} from '@ngx-translate/core';
import {TranslationKey} from '../../types/available-translations';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ToastContentComponent} from '../../components/toast-content/toast-content.component';
import {Magnet} from 'src/app/api/models/magnet';
import {ImportLocation} from '../../models/import-location';
import {ImportDialogComponent, ImportDialogData} from '../../components/dialogs/import-dialog/import-dialog.component';
import {firstValueFrom, Observable} from 'rxjs';
import {SameFileDetected} from '../../models/same-file-detected';
import {SimpleMessageDialogComponent} from '../../components/dialogs/simple-message-dialog/simple-message-dialog.component';
import {SimpleInputDialogComponent} from '../../components/dialogs/simple-input-dialog/simple-input-dialog.component';
import {InputDialogInputOption, InputDialogOptions} from '../../models/input-dialog-options';
import {BaseSnackBarComponent} from '../../components/dialogs/base-snack-bar/base-snack-bar.component';
import {SimpleMessageDialogData} from '../../models/simple-message-dialog-data';
import {take} from 'rxjs/operators';
import {AppQuery} from '../../queries/app.query';
import {SimpleDialogComponent} from '../../components/dialogs/simple-dialog/simple-dialog.component';
import {VaultMember} from '../../api/models/vault-member';
import {StatusDialogComponent} from '../../components/dialogs/status-dialog/status-dialog.component';
import {SimpleDialogData} from '../../models/simple-dialog-data';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private defaultWidth = '400px';
    private connectionLostDialog: MatDialogRef<any> | undefined;

    constructor(
        private matDialog: MatDialog,
        private translateService: TranslateService,
        private snackBar: MatSnackBar,
        private overlay: Overlay,
        private appQuery: AppQuery,
    ) {
    }

    async showVaultSelection(): Promise<Vault> {
        const dialogRef = this.matDialog.open(VaultSelectorDialogComponent, {});
        return await firstValueFrom(dialogRef.afterClosed());
    }

    showError(errorMessageKey: TranslationKey, errorObject?: Error, translateKeyParameters?: Record<string, unknown>, actionKey: TranslationKey = 'BUTTON.OK'): void {
        const message = this.translateService.instant(errorMessageKey, translateKeyParameters);
        if (errorObject) {
            console.error(message, errorObject);
        }
        this.showBaseToast({ message, appTestTag: 'error-toast-' + errorMessageKey, action: this.translateService.instant(actionKey) });
    }

    showGenericError(errorObject?: Error): void {
        this.showError('ERROR.GENERIC', errorObject, {}, undefined);
    }

    showSuccess(messageKey: TranslationKey, translateKeyParameters?: Record<string, unknown>): void {
        const message = this.translateService.instant(messageKey, translateKeyParameters);
        this.showBaseToast({ message, appTestTag: 'success-toast-' + messageKey });
    }

    showInfo(messageKey: TranslationKey, translateKeyParameters?: Record<string, unknown>): void {
        const message = this.translateService.instant(messageKey, translateKeyParameters);
        this.showBaseToast({ message, appTestTag: 'info-toast-' + messageKey });
    }

    showDialog({ messageKey, translateKeyParameters, appTestTag }: {
        messageKey: TranslationKey;
        translateKeyParameters?: Record<string, unknown>;
        appTestTag?: string;
    }): MatDialogRef<SimpleMessageDialogComponent, boolean | undefined> {
        const message = this.translateService.instant(messageKey, translateKeyParameters);
        return this.showBaseDialog({ message: message, appTestTag: appTestTag });
    }

    showConfirmDialog({ messageKey, title, confirmText, cancelText, translateKeyParameters, appTestTag, linkTargetOnConfirm }: {
        messageKey: TranslationKey;
        title?: TranslationKey;
        confirmText?: TranslationKey;
        cancelText?: TranslationKey;
        translateKeyParameters?: Record<string, unknown>;
        appTestTag?: string;
        linkTargetOnConfirm?: string;
    }, config?: MatDialogConfig): Promise<boolean> {
        return this.showBaseConfirmDialog({
            message: this.translateService.instant(messageKey, translateKeyParameters),
            title: title ? this.translateService.instant(title, translateKeyParameters) : undefined,
            confirmText: confirmText ? this.translateService.instant(confirmText, translateKeyParameters) : undefined,
            cancelText: cancelText ? this.translateService.instant(cancelText, translateKeyParameters) : undefined,
            appTestTag,
            linkTargetOnConfirm,
        }, config);
    }

    showPermanentToast(messageKey: TranslationKey, translateKeyParameters?: Record<string, unknown>, config?: MatSnackBarConfig): MatSnackBarRef<BaseSnackBarComponent> {
        const message = this.translateService.instant(messageKey, translateKeyParameters);
        return this.showBaseToast({ message, appTestTag: 'permanent-toast-' + messageKey, duration: -1, config });
    }

    showOrientationChangedDialog(msgKey: TranslationKey, translateKeyParameters?: Record<string, unknown>, config?: MatDialogConfig): MatDialogRef<SimpleDialogComponent> {
        const message = this.translateService.instant(msgKey, translateKeyParameters);
        return this.matDialog.open(SimpleDialogComponent, {
            width: this.defaultWidth,
            data: { message },
            ...config
        });
    }

    showTopToast(messageTranslationKey: TranslationKey,
                 translateKeyParameters?: Record<string, unknown>,
                 actionKey?: TranslationKey,
                 color: 'orange' | 'red' = 'orange'): { dismiss: () => void } {
        const overlayRef = this.overlay.create();
        overlayRef.hostElement.classList.add('shorten');
        const overlayConfig = this.overlay.position()
            .global()
            .centerHorizontally()
            .top('0px');
        const componentPortal = new ComponentPortal(ToastContentComponent);
        overlayRef.updatePositionStrategy(overlayConfig);
        const componentRef: ComponentRef<ToastContentComponent> = overlayRef.attach(componentPortal);
        componentRef.instance.message = this.translateService.instant(messageTranslationKey, translateKeyParameters);
        componentRef.instance.appTestTag = 'top-toast-' + messageTranslationKey;
        componentRef.instance.additionalClass = 'top-snackbar-' + color;

        return {
            dismiss() {
                overlayRef.detach();
                overlayRef.dispose();
            }
        };
    }

    showUpdateAvailableToast(version: string, installAction: () => void): OverlayRef {
        const overlayRef = this.overlay.create();
        overlayRef.hostElement.classList.add('shorten');
        const overlayConfig = this.overlay.position()
            .global()
            .right('24px')
            .bottom('24px');
        overlayRef.updatePositionStrategy(overlayConfig);
        const componentPortal = new ComponentPortal(ToastContentComponent);
        const componentRef: ComponentRef<ToastContentComponent> = overlayRef.attach(componentPortal);
        componentRef.instance.message = this.translateService.instant('ELECTRON_UPDATE_AVAILABLE', { version });
        componentRef.instance.appTestTag = 'top-toast-' + 'ELECTRON_UPDATE_AVAILABLE';
        componentRef.instance.additionalClass = 'update-reminder-toast';
        componentRef.instance.buttons = [
            {
                title: this.translateService.instant('ELECTRON_INSTALL_UPDATE'),
                action: () => {
                    installAction();
                    componentRef.instance.buttons = [];
                    componentRef.instance.message = this.translateService.instant('ELECTRON_UPDATE_DOWNLOADING');
                }
            },
            {
                title: this.translateService.instant('ELECTRON_DISMISS_UPDATE'),
                action: () => {
                    overlayRef.detach();
                    overlayRef.dispose();
                }
            }
        ];
        return overlayRef;
    }

    showStatusDialog(selectedUsers: Array<VaultMember>): MatDialogRef<StatusDialogComponent> {
        return this.matDialog.open(StatusDialogComponent, {
            id: 'status-dialog',
            backdropClass: 'for-custom-dialog',
            panelClass: 'is-status-dialog',
            disableClose: true,
            closeOnNavigation: false,
            autoFocus: false,
            data: {
                selectedUsers,
            }
        });
    }

    showImportDialog(files?: Array<File>,
                     vaultId?: string,
                     magnetId?: string,
                     target?: ImportLocation | Magnet | undefined,
                     collisionSetting?: SameFileDetected,
                     documentId?: string,
    ): MatDialogRef<ImportDialogComponent, ImportDialogData> | undefined {
        return this.matDialog.open(ImportDialogComponent, {
            id: 'import-dialog',
            backdropClass: 'for-custom-dialog',
            panelClass: 'is-import-dialog',
            disableClose: true,
            closeOnNavigation: false,
            autoFocus: false,
            data: {
                vaultId,
                magnetId,
                files,
                target,
                collisionSetting,
                documentId,
            }
        });
    }

    showInputDialog(options: InputDialogOptions, translateKeyParameters?: Record<string, unknown>): Observable<Map<string, InputDialogInputOption>> {
        if (options.title) {
            options.title = this.translateService.instant(options.title, translateKeyParameters);
        }
        if (options.description) {
            options.description = this.translateService.instant(options.description, translateKeyParameters);
        }
        if (options.saveBtnText) {
            options.saveBtnText = this.translateService.instant(options.saveBtnText, translateKeyParameters);
        }
        if (options.cancelBtnText) {
            options.cancelBtnText = this.translateService.instant(options.cancelBtnText, translateKeyParameters);
        }
        if (options.inputs) {
            for (const [, input] of options.inputs) {
                if (input.label) {
                    input.label = this.translateService.instant(input.label, translateKeyParameters);
                }
                if (input.placeholder) {
                    input.placeholder = this.translateService.instant(input.placeholder, translateKeyParameters);
                }
            }
        }
        return this.showBaseInputDialog(options);
    }

    async showChangesConfirmDialog(): Promise<boolean> {
        return await this.showConfirmDialog({
            messageKey: 'ANNOTATION.SAVING_CONFIRM_MSG',
            confirmText: 'BUTTON.YES',
            cancelText: 'BUTTON.NO',
            appTestTag: 'saving-annotation',
        });
    }

    async showCancelStampingDialog(): Promise<boolean> {
        return await this.showConfirmDialog({
            messageKey: 'STAMP.STAMPING_CANCEL_CONFIRM_MSG',
            confirmText: 'BUTTON.YES',
            cancelText: 'BUTTON.NO',
            appTestTag: 'cancel-stamping',
        });
    }

    async showConnectionLostDialog(connectionToServerCheck: () => Promise<boolean>): Promise<boolean> {
        if (this.connectionLostDialog) {
            return Promise.reject();
        }
        return new Promise((resolve, reject) => {

            const dataObj: SimpleMessageDialogData = {
                message: this.translateService.instant('ERROR.CONNECTION_LOST.MESSAGE'),
                confirmText: this.translateService.instant('ERROR.CONNECTION_LOST.BUTTON'),
                cancelText: this.translateService.instant('BUTTON.CANCEL'),
                appTestTag: 'connection-lost',
            };

            this.connectionLostDialog = this.matDialog.open(SimpleMessageDialogComponent, {
                width: this.defaultWidth,
                data: dataObj,
                disableClose: true
            });

            this.connectionLostDialog.afterClosed()
                .pipe(take(1))
                .subscribe(async isCheckingServerConnection => {
                    this.connectionLostDialog = undefined;
                    if (isCheckingServerConnection) {
                        resolve(await connectionToServerCheck());
                    } else {
                        reject();
                    }
                });
        });
    }

    private showBaseDialog(options: SimpleDialogData, config?: MatDialogConfig): MatDialogRef<SimpleMessageDialogComponent, boolean | undefined> {
        return this.matDialog.open(SimpleMessageDialogComponent, {
            width: this.defaultWidth,
            data: options,
            ...config
        });
    }

    private showBaseInputDialog(options: InputDialogOptions, config?: MatDialogConfig): Observable<Map<string, InputDialogInputOption>> {
        const dialogRef = this.matDialog.open(SimpleInputDialogComponent, {
            width: this.defaultWidth,
            data: options,
            ...config
        });

        return dialogRef.afterClosed();
    }

    private showBaseConfirmDialog(
        options: SimpleMessageDialogData,
        config?: MatDialogConfig
    ): Promise<boolean> {
        return new Promise((resolve) => {
            const dataObj: SimpleMessageDialogData = {
                ...{
                    message: '',
                    confirmText: this.translateService.instant('BUTTON.OK'),
                    cancelText: this.translateService.instant('BUTTON.CANCEL')
                },
                ...options
            };
            const dialogRef = this.matDialog.open(SimpleMessageDialogComponent, {
                width: this.defaultWidth,
                data: dataObj,
                ...config
            });
            dialogRef.afterClosed()
                .pipe(take(1))
                .subscribe(res => {
                    if (res) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    private showBaseToast({
                              message,
                              appTestTag,
                              duration = this.appQuery.getToastDelayMS(),
                              action,
                              config
                          }: { message: string; appTestTag?: string; duration?: number; action?: string; config?: MatSnackBarConfig }): MatSnackBarRef<BaseSnackBarComponent> {
        return this.snackBar.openFromComponent(BaseSnackBarComponent, {
            data: { message, appTestTag, action },
            duration,
            ...config
        });
    }
}
