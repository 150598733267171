import {Injectable} from '@angular/core';
import {PaginatedListData} from '../../models/paginated-list-data';
import {ListType} from '../../types/list-type';
import {PaginatedList} from '../../util/paginated-list';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {ListDataService} from '../list-data/list-data.service';
import {ListDataQuery} from '../../queries/list-data.query';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    latestList$: BehaviorSubject<PaginatedList<any> | undefined>;
    private lists$: BehaviorSubject<Record<string, PaginatedList<any>>>;

    constructor(
        private listDataService: ListDataService,
        private listDataQuery: ListDataQuery,
    ) {
        this.lists$ = new BehaviorSubject<Record<string, PaginatedList<any>>>({});
        this.latestList$ = new BehaviorSubject<PaginatedList<any> | undefined>(undefined);
    }

    createList<T>(name: string, listType: ListType, limit: number = 40, useCache: boolean = true, idKey?: string): PaginatedList<T> {
        const listData = this.listDataQuery.getPaginatedListData(name);
        const list: PaginatedList<T> | undefined = new PaginatedList<T>(listType, limit, idKey, this.listDataService, this);
        this.prepareList(list, name, useCache, listData);
        this.latestList$.next(list);
        return list;
    }

    getList<T>(name: string): PaginatedList<T> | undefined {
        const lists = this.lists$.getValue();
        if (lists[name]) {
            this.latestList$.next(lists[name]);
            return lists[name];
        }
        return undefined;
    }

    getOrCreateList<T>(listName: string, listType: ListType, limit?: number, cache?: boolean, idKey?: string): PaginatedList<T> {
        let list = this.getList<T>(listName);
        if (!list) {
            list = this.createList<T>(listName, listType, limit, cache, idKey);
        }
        return list;
    }

    getOrCreateListWithData<T>(listName: string, listType: ListType): PaginatedList<T> | undefined {
        const lists = this.lists$.getValue();
        if (lists[listName]) {
            return lists[listName];
        }
        const listData = this.listDataQuery.getPaginatedListData(listName);
        if (listData) {
            return this.createList<T>(listName, listType, undefined, listData.cache, listData.settings.idKey);
        }
        return undefined;
    }

    resetListStorage(): void {
        this.listDataService.resetListStorage();
    }

    setListActive(listName: string, newList: PaginatedList<any>): void {
        const lists = this.lists$.getValue();
        newList.activated = new Date();
        if (lists && !(listName in lists)) {
            lists[listName] = newList;
            this.listDataService.setListActive(listName, newList.activated);
            this.lists$.next(lists);
        }
    }

    cleanActiveListsFromStore(): void {
        this.listDataService.resetListActiveState();
        this.lists$.next({});
    }

    async reloadAllLists(): Promise<void> {
        const lists = this.lists$.getValue();
        for (const list of Object.values(lists)) {
            await list.reloadList();
        }
    }

    async getLatestList(): Promise<PaginatedList<any> | undefined> {
        return firstValueFrom(this.latestList$.pipe(take(1)));
    }

    async markItem(identifier: string): Promise<void> {
        const lists = Object.values(this.lists$.getValue());
        for (const list of lists) {
            const listIds = await list.getIds();
            if (listIds.includes(identifier)) {
                list.markItem(identifier, false);
            }
        }
    }

    async deleteItem(identifier: string): Promise<void> {
        const lists = Object.values(this.lists$.getValue());
        for (const list of lists) {
            const listIds = await list.getIds();
            if (listIds.includes(identifier)) {
                list.setItemDeleted(identifier, false);
            }
        }
    }

    private prepareList<T>(list: PaginatedList<T>, name: string, useCache: boolean, listData?: PaginatedListData): void {
        list.setCaching(useCache);
        list.setName(name);
        list.setPreFilterFunction(async (dataList, usedIdKey) => dataList);
        if (listData && useCache) {
            list.setData(listData.amount, JSON.parse(JSON.stringify(listData.data)), listData.marked, listData.deleted, listData.settings, listData.showRefreshListToast);
        }
        this.setListActive(name, list);
    }
}
