import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthQuery} from '../queries/auth.query';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DialogService} from '../services/dialog/dialog.service';
import {AuthService} from '../services/auth/auth.service';
import {AppService} from '../services/app/app.service';
import {AuthStore} from '../stores/auth.store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(
        private authQuery: AuthQuery,
        private authService: AuthService,
        private appService: AppService,
        private router: Router,
        private dialogService: DialogService,
        private authStore: AuthStore,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const accessToken = this.authQuery.getBearer();

        if (request.url.indexOf('token') >= 0) {
            return next.handle(request);
        }

        const requestUpdate: {
            params?: HttpParams;
            setHeaders?: {
                [name: string]: string | string[];
            };
        } = {};

        if (accessToken !== null) {
            requestUpdate.setHeaders = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: accessToken
            };
        }
        request = request.clone(requestUpdate);
        return next.handle(request)
            .pipe(
                tap((httpEvent: any) => {
                    // Skip request
                    if (httpEvent.type === 0) {
                        return;
                    }

                    // Workaround to get the location header
                    if (httpEvent instanceof HttpResponse) {
                        if (httpEvent.headers.has('location') && typeof httpEvent.body !== 'string') {
                            httpEvent.body.location = httpEvent.headers.get('location');
                        }
                    }
                }),
                catchError(async (err: HttpErrorResponse, caught): Promise<any> => {
                    if (err.status === 401 && !this.isRefreshing) {
                        await this.handle401Error(err);
                    }
                    // rethrow this HttpErrorResponse as we need the result / status code in some places
                    throw err;
                }),
            );
    }

    async handle401Error(error: HttpErrorResponse) {
        this.appService.showSpinner();
        const retries = this.authStore.getValue().failedLoginAttempts + 1;
        if (retries > 1) {
            this.authError(error);
        }
        this.isRefreshing = true;
        const isReLogged = await this.authService.tryToRelog();
        this.appService.hideSpinner();
        if (!isReLogged) {
            this.authError(error);
        } else {
            this.authStore.update({
                failedLoginAttempts: retries,
            });
            window.location.reload();
        }
        this.isRefreshing = false;
    }

    authError(error: HttpErrorResponse) {
        this.router.navigate(['/', 'auth', 'logout'])
            .then(() => {
                this.dialogService.showError('ERROR_AUTHENTICATION_FAILED', error);
            });
    }
}
