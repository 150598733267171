import {DEFAULT_ICONS} from './default-icons.constants';

export const ICONS = {
    ...DEFAULT_ICONS,
    SEVEN_ZIP: '7zip',
    ABTEILUNGEN: 'abteilungen',
    ACTIVATE: 'activate',
    ADD: 'add',
    ADD_AND: 'add_and',
    ADD_NEW: 'add_new',
    ADD_NOR: 'add_nor',
    ADD_OR: 'add_or',
    ADDENTRY: 'addentry',
    ADDONECOL: 'addonecol',
    ADDSUBENTRY: 'addsubentry',
    ADDTWOCOL: 'addtwocol',
    ADMINISTRATORS: 'administrators',
    AI: 'ai',
    AMAGNO: 'amagno',
    ANCHOR: 'anchor',
    ANNO_HIGHLIGHT: 'anno_highlight',
    ANNO_INK: 'anno_ink',
    ANNO_LINE: 'anno_line',
    ANNO_NO: 'anno_no',
    ANNO_SIGNATURE: 'anno_signature',
    ANNO_STICKY: 'anno_sticky',
    ANNO_SYMBOLS: 'anno_symbols',
    ANNO_TEXT: 'anno_text',
    ANNO_YES: 'anno_yes',
    ANNOTATION_OFF: 'annotation_off',
    ANNOTATION_ON: 'annotation_on',
    ANNOTATIONS: 'annotations',
    APOTHEKENLOESUNG: 'apothekenloesung',
    ARCHIV: 'archiv',
    ARCHIVED: 'archived',
    ARCHIVED_END: 'archived_end',
    ARCHIVING: 'archiving',
    ARRANGE: 'arrange',
    ARRANGE_DOWN: 'arrange_down',
    ARRANGE_LEFT: 'arrange_left',
    ARRANGE_RIGHT: 'arrange_right',
    ARRANGE_UP: 'arrange_up',
    ASSIGNTOGROUP: 'assigntogroup',
    ASSIGNTOORGANIZATION: 'assigntoorganization',
    ASTERISK: 'asterisk',
    ATTACH_FILE: 'attach_file',
    AUTOMAILIMPORT: 'automailimport',
    AVI: 'avi',
    BAT: 'bat',
    BILD_DATEI: 'bild_datei',
    BLANK_BLUE: 'blank_blue',
    BLANK_GREEN: 'blank_green',
    BLANK_GREY: 'blank_grey',
    BLANK_PURPUR: 'blank_purpur',
    BLANK_YELLOW: 'blank_yellow',
    BOXEN_EINGELAGERTEWARE: 'boxen_eingelagerteware',
    CANCEL: 'cancel',
    CANCEL_CLASSIFICATION: 'cancel_classification',
    CANCELACTION: 'cancelaction',
    CART: 'cart',
    CHECKMAILACCOUNT: 'checkmailaccount',
    CLASSIFICATIONCHECK: 'classificationcheck',
    CLASSIFICATIONINPUTREQUIRED: 'classificationinputrequired',
    CLASSIFICATIONMETADATA: 'classificationmetadata',
    CLASSIFICATIONPASSED: 'classificationpassed',
    CLASSIFICATIONPREVIEW: 'classificationpreview',
    CLASSIFY: 'classify',
    CLICKFILL: 'clickfill',
    CLIPBOARD: 'clipboard',
    CLIPBOARD_ADD: 'clipboard_add',
    CLIPBOARD_ANCHOR: 'clipboard_anchor',
    CLIPBOARD_REF: 'clipboard_ref',
    CLIPBOARD_REMOVE: 'clipboard_remove',
    CLOSE: 'close',
    CLOSE_GREY: 'close_grey',
    COL_ADD: 'col_add',
    COL_DEL: 'col_del',
    COLVIEW_DESIGN: 'colview_design',
    COLVIEW_PERSONAL: 'colview_personal',
    COMMENT: 'comment',
    COND_AND: 'cond_and',
    COND_FOLDER_FROM: 'cond_folder_from',
    COND_FOLDER_IS: 'cond_folder_is',
    COND_NOR: 'cond_nor',
    COND_OR: 'cond_or',
    CONTACT: 'contact',
    CONTACT_BLUE: 'contact_blue',
    CONTACT_GREEN: 'contact_green',
    CONTACT_GREY: 'contact_grey',
    CONTACT_PURPUR: 'contact_purpur',
    CONTACT_SELECTED: 'contact_selected',
    CONTACT_YELLOW: 'contact_yellow',
    CONTACTS: 'contacts',
    CONTACTS_SELECTED: 'contacts_selected',
    CONTACTS_UNSELECTED: 'contacts_unselected',
    CONTENT: 'content',
    CONTENT_COPY: 'content_copy',
    CONTENT_MOVE: 'content_move',
    CONTENTVIEW: 'contentview',
    COPYITEM: 'copyitem',
    CROSS: 'cross',
    CUBE_BLUE: 'cube_blue',
    CUBE_GREEN: 'cube_green',
    CUBE_GREY: 'cube_grey',
    CUBE_YELLOW: 'cube_yellow',
    DATEI: 'datei',
    DEACTIVATE: 'deactivate',
    DELETEFINALLY: 'deletefinally',
    DEPARTMENT: 'department',
    DESIGNER: 'designer',
    DLL: 'dll',
    DOC: 'doc',
    DOCTASK: 'doctask',
    DOCTASK_SELECTED: 'doctask_selected',
    DOCTASK_UNSELECTED: 'doctask_unselected',
    DOCTYPE: 'doctype',
    DOCUMENT: 'document',
    DOCVIEWCLOSE: 'docviewclose',
    DOCVIEWNEXTDOC: 'docviewnextdoc',
    DOCVIEWNEXTPAGE: 'docviewnextpage',
    DOCVIEWPREVDOC: 'docviewprevdoc',
    DOCVIEWPREVPAGE: 'docviewprevpage',
    DOCX: 'docx',
    DOKUMENTE: 'dokumente',
    DOWN: 'down',
    DOWNLOAD: 'download',
    DOWNLOADS: 'downloads',
    DREIPUNKTE: 'dreipunkte',
    DWG: 'dwg',
    DXF: 'dxf',
    EDIT: 'edit',
    EDIT_BLACK: 'edit_black',
    EDITLINK: 'editlink',
    EDITOR: 'editor',
    EINGANG: 'eingang',
    EMAILACTION: 'emailaction',
    EMAILRECIPIENT: 'emailrecipient',
    EML: 'eml',
    EMPTYCONDITION: 'emptycondition',
    EPS: 'eps',
    EXCLAMATION_MARK: 'exclamation_mark',
    EXE: 'exe',
    EXPORT: 'export',
    EYE_OFF: 'eye_off',
    EYE_ON: 'eye_on',
    FAVORITE: 'favorite',
    FAVORITE_GREY: 'favorite_grey',
    FI_ANCHOREXISTING: 'fi_anchorexisting',
    FI_AUTORENAME: 'fi_autorename',
    FI_IGNORE: 'fi_ignore',
    FI_IMPORTAGAIN: 'fi_importagain',
    FI_IMPORTWITHOUTCLASSIFICATION: 'fi_importwithoutclassification',
    FI_IMPORTWITHOUTPROPSCLASSIFICATION: 'fi_importwithoutpropsclassification',
    FI_OVERWRITE: 'fi_overwrite',
    FI_STILLIMPORT: 'fi_stillimport',
    FIELDTYPE_COUNT_BLUE: 'fieldtype_count_blue',
    FIELDTYPE_COUNT_GREEN: 'fieldtype_count_green',
    FIELDTYPE_COUNT_GREY: 'fieldtype_count_grey',
    FIELDTYPE_COUNT_PURPUR: 'fieldtype_count_purpur',
    FIELDTYPE_COUNT_YELLOW: 'fieldtype_count_yellow',
    FIELDTYPE_CURRENCY_BLUE: 'fieldtype_currency_blue',
    FIELDTYPE_CURRENCY_GREEN: 'fieldtype_currency_green',
    FIELDTYPE_CURRENCY_GREY: 'fieldtype_currency_grey',
    FIELDTYPE_CURRENCY_PURPUR: 'fieldtype_currency_purpur',
    FIELDTYPE_CURRENCY_YELLOW: 'fieldtype_currency_yellow',
    FIELDTYPE_DATETIME_BLUE: 'fieldtype_datetime_blue',
    FIELDTYPE_DATETIME_GREEN: 'fieldtype_datetime_green',
    FIELDTYPE_DATETIME_GREY: 'fieldtype_datetime_grey',
    FIELDTYPE_DATETIME_PURPUR: 'fieldtype_datetime_purpur',
    FIELDTYPE_DATETIME_YELLOW: 'fieldtype_datetime_yellow',
    FIELDTYPE_DIGIT_BLUE: 'fieldtype_digit_blue',
    FIELDTYPE_DIGIT_GREEN: 'fieldtype_digit_green',
    FIELDTYPE_DIGIT_GREY: 'fieldtype_digit_grey',
    FIELDTYPE_DIGIT_PURPUR: 'fieldtype_digit_purpur',
    FIELDTYPE_DIGIT_YELLOW: 'fieldtype_digit_yellow',
    FIELDTYPE_LIST_BLUE: 'fieldtype_list_blue',
    FIELDTYPE_LIST_GREEN: 'fieldtype_list_green',
    FIELDTYPE_LIST_GREY: 'fieldtype_list_grey',
    FIELDTYPE_LIST_PURPUR: 'fieldtype_list_purpur',
    FIELDTYPE_LIST_YELLOW: 'fieldtype_list_yellow',
    FIELDTYPE_TEXT_BLUE: 'fieldtype_text_blue',
    FIELDTYPE_TEXT_GREEN: 'fieldtype_text_green',
    FIELDTYPE_TEXT_GREY: 'fieldtype_text_grey',
    FIELDTYPE_TEXT_PURPUR: 'fieldtype_text_purpur',
    FIELDTYPE_TEXT_YELLOW: 'fieldtype_text_yellow',
    FILEEXPORT: 'fileexport',
    FILEIMPORT: 'fileimport',
    FILTER: 'filter',
    FIND: 'find',
    FIND_SELECTED: 'find_selected',
    FIND_UNSELECTED: 'find_unselected',
    FINDINGS: 'findings',
    FINISHED: 'finished',
    FLAG: 'flag',
    FOLDER: 'folder',
    FRAGEZEICHEN: 'fragezeichen',
    GEBAEUDE: 'gebaeude',
    GIF: 'gif',
    GIFT: 'gift',
    GLOBE: 'globe',
    GUESTS: 'guests',
    HAKEN: 'haken',
    HEART: 'heart',
    HERZ: 'herz',
    HIGHLIGHT: 'highlight',
    HISTROY: 'histroy',
    HOURGLAS: 'hourglas',
    HOURGLASS_EMPTY: 'hourglass_empty',
    ICON_SONSTIGE: 'icon_sonstige',
    IMPORT: 'import',
    INBOX: 'inbox',
    INDUSTRY: 'industry',
    INF: 'inf',
    INFOBOX_OFF: 'infobox_off',
    INFOBOX_ON: 'infobox_on',
    INIT_CLASSIFICATION: 'init_classification',
    INK: 'ink',
    INPROGRESS: 'inprogress',
    INPUTSUPPORTSEARCH: 'inputsupportsearch',
    INVITATION: 'invitation',
    INVITATIONPIN: 'invitationpin',
    ITEM_CANCELEDIT: 'item_canceledit',
    ITEM_CHECKIN: 'item_checkin',
    ITEM_CHECKOUT: 'item_checkout',
    ITEM_CHECKOUT_MYSELF: 'item_checkout_myself',
    ITEM_CHECKOUT_OTHER: 'item_checkout_other',
    ITEMREF: 'itemref',
    ITEMREF_DOWN: 'itemref_down',
    ITEMREF_UP: 'itemref_up',
    JPG: 'jpg',
    JSON: 'json',
    KEY: 'key',
    KEYWORD: 'keyword',
    KREUZ: 'kreuz',
    KUNDEN: 'kunden',
    LASTEDIT: 'lastedit',
    LASTEDITED: 'lastedited',
    LAYOUT: 'layout',
    LEAVEUSERGROUP: 'leaveusergroup',
    LEHRER: 'lehrer',
    LEVELUP: 'levelup',
    LIEFERANTEN: 'lieferanten',
    LINE: 'line',
    LISTE: 'liste',
    LISTE_2: 'liste_2',
    LISTE_3: 'liste_3',
    LOGOFF: 'logoff',
    MAENNLICH: 'maennlich',
    MAGNET: 'magnet',
    MAGNET_2: 'magnet_2',
    MAGNET_GREY: 'magnet_grey',
    MAGNET_GREY_WITH_ANCHOR: 'magnet_grey_with_anchor',
    MAGNET_WITH_ANCHOR: 'magnet_with_anchor',
    MAIL: 'mail',
    MAIN: 'main',
    MASSOPERATION: 'massoperation',
    MEGAFON: 'megafon',
    MENU: 'menu',
    MERGE: 'merge',
    MESSAGEFILTER: 'messagefilter',
    MESSAGEFILTER_BIG: 'messagefilter_big',
    MESSAGEFILTER_MEDIUM: 'messagefilter_medium',
    MESSAGEFILTER_SMALL: 'messagefilter_small',
    MESSAGES: 'messages',
    MESSAGES_SELECTED: 'messages_selected',
    MESSAGES_UNSELECTED: 'messages_unselected',
    MONITORMITPROZESS: 'monitormitprozess',
    MP3: 'mp3',
    MPEG: 'mpeg',
    MSG: 'msg',
    MUENZEN_GELD: 'muenzen_geld',
    MULTIPROPERTYGROUP_BLUE: 'multipropertygroup_blue',
    MULTIPROPERTYGROUP_GREEN: 'multipropertygroup_green',
    MULTIPROPERTYGROUP_GREY: 'multipropertygroup_grey',
    MULTIPROPERTYGROUP_PURPUR: 'multipropertygroup_purpur',
    MULTIPROPERTYGROUP_YELLOW: 'multipropertygroup_yellow',
    NAVIGATE_LEFT: 'navigate_left',
    NAVIGATE_NEXT: 'navigate_next',
    NAVIGATE_RIGHT: 'navigate_right',
    NEWIMPORT: 'newimport',
    NEWITEM: 'newitem',
    NEWITEMS: 'newitems',
    NEWITEMTASKBAR: 'newitemtaskbar',
    OK: 'ok',
    OP_ALL: 'op_all',
    OP_BEGINWITH: 'op_beginwith',
    OP_EQUAL: 'op_equal',
    OP_EXISTS: 'op_exists',
    OP_FUTURE: 'op_future',
    OP_GTOE: 'op_gtoe',
    OP_HIGHERTHAN: 'op_higherthan',
    OP_LOWERTHAN: 'op_lowerthan',
    OP_LTOE: 'op_ltoe',
    OP_NEARBY: 'op_nearby',
    OP_PAST: 'op_past',
    OP_REGEX: 'op_regex',
    OP_SOME: 'op_some',
    OPERATION: 'operation',
    OPERATION_SELECTED: 'operation_selected',
    OPERATION_UNSELECTED: 'operation_unselected',
    OPTION_EMPTY: 'option_empty',
    OPTION_NO: 'option_no',
    OPTION_SELECTED: 'option_selected',
    OPTION_YES: 'option_yes',
    ORGANISATION: 'organisation',
    ORGANISATION_SELECTED: 'organisation_selected',
    ORGANISATION_UNSELECTED: 'organisation_unselected',
    ORGANISATIONS: 'organisations',
    PAGE: 'page',
    PAGE_NEXT: 'page_next',
    PAGE_PREV: 'page_prev',
    PALETTE: 'palette',
    PAUSE: 'pause',
    PDF: 'pdf',
    PDF_NICHT_TRENNEN: 'pdf_nicht_trennen',
    PDF_TRENNEN: 'pdf_trennen',
    PDF_TRENNEN_RUECKGAENGIG: 'pdf_trennen_rueckgaengig',
    PDF_TRENNUNG: 'pdf_trennung',
    PERMISSION_COMMON: 'permission_common',
    PERMISSION_DENIED: 'permission_denied',
    PERMISSION_DETAIL: 'permission_detail',
    PERMISSION_GRANTED: 'permission_granted',
    PERMISSIONDELETED: 'permissiondeleted',
    PERMISSIONNEUTRAL: 'permissionneutral',
    PERMISSIONS: 'permissions',
    PERSONAL: 'personal',
    PERSONAL_SEARCHQUERY: 'personal_searchquery',
    PERSONALAKTE: 'personalakte',
    PIN_OFF: 'pin_off',
    PIN_ON: 'pin_on',
    PIN_UNABLE: 'pin_unable',
    PLACEOFDISCOVERY: 'placeofdiscovery',
    PLAY: 'play',
    PLUS: 'plus',
    PNG: 'png',
    POWER: 'power',
    PPT: 'ppt',
    PPTX: 'pptx',
    PREVIEW: 'preview',
    PRICING: 'pricing',
    PRINTERDRIVER: 'printerdriver',
    PROBLEMS: 'problems',
    PROCESS: 'process',
    PROPERTIES: 'properties',
    PROPERTIES_GREEN: 'properties_green',
    PROPGROUP: 'propgroup',
    PROPGROUP_BLUE: 'propgroup_blue',
    PROPGROUP_GREEN: 'propgroup_green',
    PROPGROUP_GREY: 'propgroup_grey',
    PROPGROUP_PURPUR: 'propgroup_purpur',
    PROPGROUP_YELLOW: 'propgroup_yellow',
    PROZESS: 'prozess',
    PS1: 'ps1',
    PSD: 'psd',
    RAR: 'rar',
    RAW: 'raw',
    RECHNUNG: 'rechnung',
    REFRESH: 'refresh',
    REFRESH_VIEW: 'refresh_view',
    REFRESHVIEW: 'refreshview',
    REGISTERNEWUSER: 'registernewuser',
    REM_INK_BLACK: 'rem_ink_black',
    REM_INK_BLUE: 'rem_ink_blue',
    REM_INK_GREEN: 'rem_ink_green',
    REM_INK_RED: 'rem_ink_red',
    REM_LINE_BLACK: 'rem_line_black',
    REM_LINE_BLUE: 'rem_line_blue',
    REM_LINE_GREEN: 'rem_line_green',
    REM_LINE_RED: 'rem_line_red',
    REM_LINE_YELLOW: 'rem_line_yellow',
    REM_MARKER_BLACK: 'rem_marker_black',
    REM_MARKER_BLUE: 'rem_marker_blue',
    REM_MARKER_GREEN: 'rem_marker_green',
    REM_MARKER_RED: 'rem_marker_red',
    REM_MARKER_YELLOW: 'rem_marker_yellow',
    REM_NOTICE_BLUE: 'rem_notice_blue',
    REM_NOTICE_GREEN: 'rem_notice_green',
    REM_NOTICE_RED: 'rem_notice_red',
    REM_NOTICE_YELLOW: 'rem_notice_yellow',
    REM_SIGNATURE_BLUE: 'rem_signature_blue',
    REM_TEXT_BLUE: 'rem_text_blue',
    REM_TEXT_GREEN: 'rem_text_green',
    REM_TEXT_RED: 'rem_text_red',
    REM_TEXT_YELLOW: 'rem_text_yellow',
    REMEMBER_DOWN: 'remember_down',
    REPAIR: 'repair',
    RESTORE: 'restore',
    ROTATE: 'rotate',
    ROTATE_LEFT: 'rotate_left',
    ROTATE_RIGHT: 'rotate_right',
    RTF: 'rtf',
    RUBBERSTAMP: 'rubberstamp',
    RUBBERSTAMP_REPEAT: 'rubberstamp_repeat',
    RUBBERSTAMP_WHITE: 'rubberstamp_white',
    SAVE: 'save',
    SAVECLOSE: 'saveclose',
    SAVENEXT: 'savenext',
    SC_ADDROW: 'sc_addrow',
    SC_SPLITROW: 'sc_splitrow',
    SCANIMPORT: 'scanimport',
    SCANNER: 'scanner',
    SEARCH_LOCAL: 'search_local',
    SEARCH_OVERALL: 'search_overall',
    SEARCHBOX_ICON: 'searchbox_icon',
    SEARCHBOX_SEARCH: 'searchbox_search',
    SEEALSO: 'seealso',
    SELECTION: 'selection',
    SENDFILE: 'sendfile',
    SENDTO: 'sendto',
    SENDWEBLINK: 'sendweblink',
    SEPERATOR: 'seperator',
    SERVER: 'server',
    SETTINGS: 'settings',
    SHOPPING_BAG: 'shopping_bag',
    SIGNATURE: 'signature',
    SMARTFILTER_OFF: 'smartfilter_off',
    SMARTFILTER_ON: 'smartfilter_on',
    SOLARPANNEL: 'solarpannel',
    SORT: 'sort',
    SORTING: 'sorting',
    STAMP_CATEGORY: 'stamp_category',
    STAMPPREVIEW: 'stamppreview',
    STEIGENDERGRAPH: 'steigendergraph',
    STEMP_CANCEL_NEXT_PAGE: 'stemp_cancel_next_page',
    STEMP_OK_NEXT_PAGE: 'stemp_ok_next_page',
    STICKY: 'sticky',
    STOREDSEARCH_PERSONAL: 'storedsearch_personal',
    STOREDSEARCH_PUBLIC: 'storedsearch_public',
    SUPPORT: 'support',
    SUPPORTDO: 'supportdo',
    SWITCH: 'switch',
    SWITCH_TAG_GROUP_DOWN: 'switch_tag_group_down',
    SWITCH_TAG_GROUP_UP: 'switch_tag_group_up',
    SYMBOLS: 'symbols',
    SYNC: 'sync',
    TAB_CLASS_VALIDATION: 'tab_class_validation',
    TAB_DUPLICATION_CHECK: 'tab_duplication_check',
    TAB_FIELDS: 'tab_fields',
    TAB_HISTORY: 'tab_history',
    TAB_MAGNETS: 'tab_magnets',
    TAB_MESSAGES: 'tab_messages',
    TAB_PERMISSIONS: 'tab_permissions',
    TAB_PROPERTIES: 'tab_properties',
    TAB_SEEALSO: 'tab_seealso',
    TAB_SETTINGS: 'tab_settings',
    TAB_SOLUTIONSTORE: 'tab_solutionstore',
    TAB_TAGS: 'tab_tags',
    TABMAILSETTINGS: 'tabmailsettings',
    TASK_GLOBAL: 'task_global',
    TEXT: 'text',
    TEXTPOSITIONCONDITION: 'textpositioncondition',
    THUMBS_DOWN: 'thumbs_down',
    THUMBS_UP: 'thumbs_up',
    TIFF: 'tiff',
    TIMELINE: 'timeline',
    TRASH: 'trash',
    TRASHING: 'trashing',
    TREEITEM: 'treeitem',
    TXT: 'txt',
    UNASSIGNFROMGROUP: 'unassignfromgroup',
    URLAUB: 'urlaub',
    USER: 'user',
    USERGROUP: 'usergroup',
    USERGROUPCOPY: 'usergroupcopy',
    USERTASK: 'usertask',
    VALUERELTOTEXTCONDITION: 'valuereltotextcondition',
    VALUERONFEXITPOSITION: 'valueronfexitposition',
    VAULT: 'vault',
    VAULT_SEARCH_QUERY: 'vault_search_query',
    VAULT_SELECTED: 'vault_selected',
    VAULT_UNSELECTED: 'vault_unselected',
    VAULTQUIT: 'vaultquit',
    VAULTS: 'vaults',
    VERSION_RESTORED: 'version_restored',
    VIEW_COLUMNS: 'view_columns',
    VIEW_FULLPAGE: 'view_fullpage',
    VIEW_ICONS: 'view_icons',
    VIEW_LISTVIEW: 'view_listview',
    VIEW_PREVIEW_MEDIUM: 'view_preview_medium',
    VIEW_PREVIEW_SMALL: 'view_preview_small',
    VORLAGEN: 'vorlagen',
    WAAGE: 'waage',
    WARE: 'ware',
    WEBLINK: 'weblink',
    WEIBLICH: 'weiblich',
    WERKZEUG: 'werkzeug',
    WIEDERVORLAGE: 'wiedervorlage',
    WITHOUTMAGNET: 'withoutmagnet',
    XLS: 'xls',
    XLSX: 'xlsx',
    ZAHNRAEDER: 'zahnraeder',
    ZIP: 'zip',
    ZOOM: 'zoom',
    ZOOM_IN: 'zoom_in',
    ZOOM_OUT: 'zoom_out',

    // OLD ICONS
    //KEYBOARD_ARROW_LEFT: 'keyboard_arrow_left', //currently provided by material icons
} as const;
