import {Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ListItem} from '../../../models/list/list-item.model';
import {RouterLink} from '@angular/router';
import {HistoryService} from '../../../services/history/history.service';
import {ListItemIconComponent} from '../list-item-icon/list-item-icon.component';
import {QA_TAG_NAME} from '../../../constants/qa/qa-tag-name.contant';

@Component({
    selector: 'app-list-item',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        NgOptimizedImage,
        ListItemIconComponent
    ],
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {
    @Input() item!: ListItem;
    @ViewChild('link', { static: true }) linkElement: ElementRef | undefined;
    @ViewChildren('preventLinkClick') preventClickElements: QueryList<ElementRef> | undefined;
    listItemQaTagPrefix: string | undefined;

    constructor(
        private historyService: HistoryService,
        private element: ElementRef,
    ) {
    }

    /**
     * To prevent a click on a child element from opening the link, declare
     * the #preventLinkClick template variable on that child.
     */
    @HostListener('click', ['$event']) clickLink(event: MouseEvent) {
        if (this.preventClickElements?.find(element => element.nativeElement === event.target)) {
            return;
        }

        if (this.linkElement?.nativeElement === event.target) {
            return;
        }

        this.linkElement?.nativeElement.click();
    }

    ngOnInit(): void {
        this.listItemQaTagPrefix = this.element.nativeElement.getAttribute(QA_TAG_NAME) ?? undefined;
    }

    addNavigationHistoryItem(): void {
        if (this.item?.navigationHistoryItem) {
            this.historyService.addNavigationHistory(this.item.navigationHistoryItem);
        }
    };
}
